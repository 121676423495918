import { Component, Inject } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { AuthService } from '../../auth-module/services/auth.service'
import { UserService } from 'src/app/services/user.service';
import { finalize } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import { helper } from 'src/environments/helper';
import { User } from 'src/app/models';

@Component({
    selector: 'app-taxa-dezvoltare-urbana',
    templateUrl: './taxa-dezvoltare-urbana.component.html',
    styleUrls: ['./taxa-dezvoltare-urbana.component.scss']
})
export class TaxaDezvoltareUrbanaComponent {
    PageSection: any = null
    portalLive: boolean = environment.portalLive;
    FAQList: { [key: string]: any[] } = {};
    isLoading = false;
    // titles: string[] = ['Inform', 'Înregistrare cont preînrolat', 'Autentificare/Login', 'Recuperare parolă cont', 'DGITL - Registratură']
    slugs: string[] = ['tsdu-info', 'tsdu-cet-rol-fiscal', 'tsdu-cet-fara-rol-fiscal', 'tsdu-payment', 'tsdu-cet-rol-fiscal-loc-nominal'];

    loggedUser: null | User;

    hclUrl: string = "https://ps4.ro/wp-content/uploads/2024/12/H_272-af.pdf"

    urlType: string = '/public/solicitari/dgitl';
    // urlType: string = '/dashboard/solicitari/institutie/dgitl';

    public sumarList: any = helper.modules.dgitl.taxaSpecialaDezvoltareUrbana.sectionForms.sumarList

    public scutiriLabels: any = helper.modules.dgitl.taxaSpecialaDezvoltareUrbana.sectionForms.scutiriLabels
    public filteredScutiri: any[] = [];
    public alteSolicitariLabels: any = helper.modules.dgitl.taxaSpecialaDezvoltareUrbana.sectionForms.alteSolicitariLabels
    public filteredAlteSolicitari: any[] = [];

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private notificationService: NotificationService,
        private viewportScroller: ViewportScroller,
        private router: Router
    ) {
        // console.log(environment.taxAllowed, 'check tax allowed?')

        this.loggedUser = this.getUser();

        if (!environment.taxAllowed) {
            // Redirect to home route immediately in the constructor.
            // Note: This might not be the best place depending on your logic. See notes below.
            this.router.navigate(['/']);
        }
    }

    public onClick(elementId: string): void {
        console.log(elementId, 'item')
        this.viewportScroller.scrollToAnchor(elementId);
    }

    redirectTo(url: any = null, personType?: number) {
        window.open(url, '_blank');
    }

    redirectToDgitl(personTypeId: number, catId: number, rowId: number) {
        // here we need to check if user is authenticated, because we need to redirect to the the right page
        // if user is not authenticated, we need to redirect to the public page
        let url = `#${this.urlType}?personTypeId=${personTypeId}&catId=${catId}&rowId=${rowId}`
        window.open(url, '_blank');
    }

    ngOnInit(): void {
        if (environment.taxAllowed) {
            this.loadFAQs();
        }

        this.filterScutiri();
        this.filterAlteSolicitari();
    }

    getFAQS(slug: string) {
        this.isLoading = true

        this.authService.getFAQ(slug)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: async (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error)
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.FAQList[slug] = response.data
                            this.isLoading = false
                            return;
                        }
                    } else {
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        await this.notificationService.warningSwal(environment.config.customNotifications.headers.error, errorMessage, environment.config.customNotifications.icons.error);
                    }
                },
                error: (res: any) => {
                    this.notificationService.handleHttpError(res.error);
                }
            });
    }

    loadFAQs() {
        for (const slug of this.slugs) {
            this.getFAQS(slug);
        }
    }

    redirectToDMU() {
        window.open("https://info.mobilitateurbana4.ro", '_blank');
    }

    getUser() {
        const user = localStorage.getItem(environment.config.userKey);
        if (this.authService.isAuthenticated() && user) {
            return JSON.parse(user);
        } else {
            return null;
        }
    }

    filterScutiri() {
        if (!this.loggedUser) {
            this.filteredScutiri = this.scutiriLabels;
        } else {
            if (this.loggedUser.type === 1) {
                this.filteredScutiri = this.scutiriLabels.filter((item: any) => item.formsAllowed.pf === true);
            } else if (this.loggedUser.type === 2) {
                this.filteredScutiri = this.scutiriLabels.filter((item: any) => item.formsAllowed.pj === true);
            }
        }
    }

    filterAlteSolicitari() {
        if (!this.loggedUser) {
            this.filteredAlteSolicitari = this.alteSolicitariLabels;
        } else {
            if (this.loggedUser.type === 1) {
                this.filteredAlteSolicitari = this.alteSolicitariLabels.filter((item: any) => item.formsAllowed.pf === true);
            } else if (this.loggedUser.type === 2) {
                this.filteredAlteSolicitari = this.alteSolicitariLabels.filter((item: any) => item.formsAllowed.pj === true);
            }
        }
    }
}
