import { Component, OnInit } from '@angular/core';
import { CommonService } from './services/common.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    title = 'portal-cetatean';

    constructor(private commonService: CommonService) { }

    ngOnInit(): void {
        const storedTari = sessionStorage.getItem(environment.config.storageKey + 'tari');
        const storedJudete = sessionStorage.getItem(environment.config.storageKey + 'judete');

        if (!storedTari) {
            this.commonService.getTariActive().subscribe({
                next: (results) => {
                    sessionStorage.setItem(environment.config.storageKey + 'tari', JSON.stringify(results[0].id));
                },
                error: (error) => {
                    console.error('Error loading tari:', error);
                }
            });
        }

        if (!storedJudete) {
            this.commonService.getJudeteActive().subscribe({
                next: (results) => {
                    sessionStorage.setItem(environment.config.storageKey + 'judete', JSON.stringify(results));
                },
                error: (error) => {
                    console.error('Error loading judete:', error);
                }
            });
        }
    }
}
