<div class="header-card">
    <div class="header-card_header">
        <img src="assets/images/Group 9.svg" alt="" />
        <div class="header-card_header-headline">
            <h1>Alegeți către ce instituție doriți să inițiați solicitarea</h1>
        </div>
    </div>
</div>
<div class="buttons-container">
    <div class="section2 container-fluid">
        <div class="section2-content d-flex gap-3 flex-column flex-xl-row">
            <div class="row">
                <div class="section2-content-card d-flex align-items-start me-1 mb-2">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <span>Depune o cerere pentru</span>
                        <img src="assets/icons/arrow-big.svg" />
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap w-100">
                <div (click)="navigateToNew(loggedUserLinks.solicitariUrbanismPlatform)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/urbanism-v1.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Generală de Urbanism și Amenajarea Teritoriului Sector 4</span>
                        <!-- <span class="tag">#TaxeȘiImpozite</span> -->
                    </div>
                </div>

                <div (click)="navigateToNew(loggedUserLinks.solicitariDgitlAccount)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgitl4.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Generală de Impozite și Taxe Locale</span>
                        <!-- <span class="tag">#TaxeȘiImpozite</span> -->
                    </div>
                </div>
                
                <div (click)="navigateToNewTab('https://mobilitateurbana4.ro')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/mobilitate-urbana.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Mobilitate Urbană</span>
                        <!-- <span class="tag">#MobilitateUrbană</span> -->
                    </div>
                </div>
                <div (click)="navigateToNew(loggedUserLinks.solicitariAutorizariComerciale)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/autorizari-comerciale.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Serviciul Autorizări Comerciale</span>
                        <!-- <span class="tag">#AutorizăriComerciale</span> -->
                    </div>
                </div>

                <div (click)="navigateToNew(loggedUserLinks.programariAccount)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgep.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Generală de Evidență a Persoanelor</span>
                    </div>
                </div>

                <div *ngIf="!isProduction()" (click)="navigateToNew(loggedUserLinks.solicitariAsistentaSociala)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgep.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Generală de Asistență Socială și Protecția Copilului</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>