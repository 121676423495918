import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BeneficiarService } from '../../../services/beneficiar.service';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { ToastService } from '../../../services/toast.service';
import { environment } from 'src/environments/environment';
import { Address } from '../../../models';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../auth-module/services/auth.service';
import { UploadDocument } from '../../solicitari/models';
import { notDefaultValidator } from '../../../shared/validators/not-default';
import * as moment from 'moment';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { Generic } from 'src/app/interfaces/generic.interface';
import { CommonService } from 'src/app/services/common.service';


@Component({
    selector: 'app-beneficiar',
    templateUrl: './beneficiar.component.html',
    styleUrls: ['./beneficiar.component.scss']
})
export class BeneficiarComponent implements OnInit {
    @ViewChild('topBeneficiarForm', { static: false }) topBeneficiarForm!: ElementRef;

    beneficiars: any[] = [];
    isLoading: boolean = false;
    beneficiarForm: FormGroup;
    addressForm: FormGroup
    showBeneficiarForm: boolean = false;
    formUpload: FormGroup;
    uploadDocs: UploadDocument[] = [{ key: 'imputernicire', name: 'Imputernicire', uploading: false }]
    minExpiryDate!: Date;

    public isUpdate: boolean = false;
    judete: Generic[] = JSON.parse(sessionStorage.getItem(environment.config.storageKey + 'judete') || '[]');
    filteredJudete!: any[];
    judeteControl = new FormControl();

    localitati: Generic[] = [];
    localitatiControl = new FormControl();
    isLocalitatiLoading = false;
    showLocalitatiMessage = true;
    localitatiLoadComplete = false;
    judetSelected = false;

    env: any = environment;
    generalErrorMessage: string = environment.config.customNotifications.generalMessages.error
    errorTitle: string = environment.config.customNotifications.headers.error
    errorIcon: string = environment.config.customNotifications.icons.error
    successTitle: string = environment.config.customNotifications.headers.success
    successIcon: string = environment.config.customNotifications.icons.success

    /*addressTypes = [
        { value: -1, label: '-- Alegeți --' },
        { value: 1, label: 'Adresa domiciliu' },
        { value: 2, label: 'Sediu social' },
        { value: 3, label: 'Resedinta' }
    ];*/

    ciTypes = [
        { value: -1, label: '-- Alegeți --' },
        { value: 1, label: 'CI' },
        { value: 2, label: 'BI' }
    ];

    beneficiarTypes = [
        { value: '-1', label: '-- Alegeți --' },
        { value: '1', label: 'Persoană Fizică' },
        { value: '2', label: 'Persoană Juridică' }
    ];

    defaultParameter: { name: string; id: string } = {
        name: '-- Alegeți --',
        id: '-1',
    };

    constructor(
        private beneficiarService: BeneficiarService,
        private fb: FormBuilder,
        private notificationService: NotificationService,
        private toastService: ToastService,
        private userService: UserService,
        private authService: AuthService,
        private commonService: CommonService
    ) {
        this.addressForm = new FormGroup({
            type: new FormControl(-1, notDefaultValidator("-1")),
            judet: new FormControl('-1', notDefaultValidator("-1")),
            oras_id: new FormControl('', Validators.required),
            oras: new FormControl('', Validators.required),
            sector: new FormControl(null, []),
            strada: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(environment.config.validatorsAccrossApp.maxStringLength),
            ])),
            bloc: new FormControl('', []),
            numar: new FormControl('', Validators.required),
            scara: new FormControl('', []),
            etaj: new FormControl('', []),
            apartament: new FormControl('', []),
            observatii: new FormControl('', []),
            adresa_type: new FormControl(2, [Validators.required]),
            id_adresa: new FormControl('')
        });

        this.formUpload = fb.group({
            imputernicire: this.fb.array([]),
        });

        this.beneficiarForm = this.fb.group({
            id: [''],
            nume: [''],
            prenume: [''],
            ci_serie: [''],
            ci_numar: [''],
            ci_type: [-1],
            ci_emitent: [''],
            cnp_cui: ['', Validators.required],
            beneficiar_type: ['-1', notDefaultValidator('-1')],
            denumire: [''],
            reg_com: [''],
            expiry_date: [''],
            address: this.addressForm,
            files: this.formUpload
        });

        this.judeteControl.valueChanges.pipe(
            distinctUntilChanged(),
            switchMap((inputValue) => {
                if (inputValue.length < 1) {
                    this.filteredJudete = this.judete;
                    return of([]);
                }
                return this.filterJudete(inputValue);
            }),
            tap((data: any[]) => {
                this.filteredJudete = data;
            }),
            catchError(() => of([]))
        ).subscribe();

        this.localitatiControl.valueChanges
            .pipe(debounceTime(600))
            .subscribe(localitate => {
                this.fetchLocalitati(localitate);
                this.showLocalitatiMessage = true;
                this.localitatiLoadComplete = false;
            });
    }

    ngOnInit(): void {
        this.fetchBeneficiars();

        this.minExpiryDate = new Date();
        this.filteredJudete = [...this.judete];
    }

    beneficiarTypeString(beneficiarTypeValue: string) {
        return this.beneficiarTypes.find((bt) => bt.value == beneficiarTypeValue)?.label;
    }

    // getters for beneficiar form controls
    get nume() {
        return this.beneficiarForm.get('nume');
    }

    get prenume() {
        return this.beneficiarForm.get('prenume');
    }

    get cnpCui() {
        return this.beneficiarForm.get('cnp_cui');
    }

    get beneficiarType() {
        return this.beneficiarForm.get('beneficiar_type');
    }

    get denumireFirma() {
        return this.beneficiarForm.get('denumire');
    }

    get expiryDate() {
        return this.beneficiarForm.get('expiry_date');
    }

    get ciType() {
        return this.beneficiarForm.get('ci_type');
    }

    get ciSerie() {
        return this.beneficiarForm.get('ci_serie');
    }

    get ciNumar() {
        return this.beneficiarForm.get('ci_numar');
    }

    get ciEmitent() {
        return this.beneficiarForm.get('ci_emitent');
    }


    // getters for address form controls
    get typeOfAddress() {
        return this.addressForm.get('type');
    }

    get judet() {
        return this.addressForm.get('judet');
    }

    get orasId() {
        return this.addressForm.get('oras_id');
    }

    get oras() {
        return this.addressForm.get('oras');
    }

    get sector() {
        return this.addressForm.get('sector');
    }

    get strada() {
        return this.addressForm.get('strada');
    }

    get numar() {
        return this.addressForm.get('numar');
    }

    // getter for imputernicire file upload
    get imputernicireFormArray() {
        return this.formUpload.get('imputernicire') as FormArray;
    }

    isPF(beneficiar: any = null) {
        if (beneficiar)
            return beneficiar.beneficiar_type == '1';

        return this.beneficiarType?.value == '1';
    }

    isPJ(beneficiar: any = null) {
        if (beneficiar)
            return beneficiar.beneficiar_type == '2';

        return this.beneficiarType?.value == '2';
    }

    fetchBeneficiars(): void {
        this.isLoading = true;
        this.beneficiarService.getBeneficiars()
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (res: any) => {
                    console.log(res.data.domiciliu);
                    this.beneficiars = res.data;
                },
                error: (err: any) => {
                    console.log(err);
                }
            });
    }

    get beneficiariPF() {
        return this.beneficiars?.filter(b => b.beneficiar_type == '1');
    }

    get beneficiariPJ() {
        return this.beneficiars?.filter(b => b.beneficiar_type == '2');
    }

    toggleBeneficiarForm() {
        if (!this.showBeneficiarForm) {
            this.beneficiarForm.reset({
                id: '',
                nume: '',
                prenume: '',
                ci_serie: '',
                ci_numar: '',
                ci_type: -1,
                ci_emitent: '',
                cnp_cui: '',
                beneficiar_type: '-1',
                denumire: '',
                reg_com: '',
                expiry_date: '',
                address: '',
            });

            this.addressForm.reset({
                type: -1,
                nume: '',
                judet: '-1',
                oras_id: '',
                oras: '',
                sector: null,
                strada: '',
                bloc: '',
                numar: '',
                scara: '',
                etaj: '',
                apartament: '',
                observatii: '',
                adresa_type: 2,
                id_adresa: '',
            });
        }
        this.showBeneficiarForm = !this.showBeneficiarForm;
        this.isUpdate = false;
        this.beneficiarType?.enable();
    }

    setValidators(form: FormGroup, controlName: string, validators: ValidatorFn[]) {
        form.controls[controlName].clearValidators();
        form.controls[controlName].setValidators(validators);
        form.controls[controlName].updateValueAndValidity();
    }

    removeValidators(form: FormGroup, controlName: string) {
        form.controls[controlName].clearValidators();
        form.controls[controlName].updateValueAndValidity();
    }

    setPfValidators() {
        this.removeValidators(this.beneficiarForm, 'denumire');

        this.setValidators(this.beneficiarForm, 'nume', [Validators.required]);
        this.setValidators(this.beneficiarForm, 'prenume', [Validators.required]);
        this.setValidators(this.beneficiarForm, 'ci_serie', [Validators.required]);
        this.setValidators(this.beneficiarForm, 'ci_numar', [Validators.required]);
        this.setValidators(this.beneficiarForm, 'ci_type', [notDefaultValidator('-1')]);
        this.setValidators(this.beneficiarForm, 'ci_emitent', [Validators.required]);
    }

    setPjValidators() {
        this.removeValidators(this.beneficiarForm, 'nume');
        this.removeValidators(this.beneficiarForm, 'prenume');
        this.removeValidators(this.beneficiarForm, 'ci_serie');
        this.removeValidators(this.beneficiarForm, 'ci_numar');
        this.removeValidators(this.beneficiarForm, 'ci_type');
        this.removeValidators(this.beneficiarForm, 'ci_emitent');

        this.setValidators(this.beneficiarForm, 'denumire', [Validators.required]);
    }

    showUpdateBeneficiar(beneficiar: any) {
        this.isLoading = true;
        this.beneficiarService.findBeneficiar(beneficiar.id)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: (res: any) => {
                    const beneficiar = res.data;
                    this.beneficiarForm.patchValue({ ...beneficiar });

                    if (beneficiar.expiry_date)
                        this.expiryDate?.setValue(moment(beneficiar.expiry_date).format('YYYY-MM-DD'));

                    if (this.isPF()) {
                        this.setPfValidators();
                    }

                    if (this.isPJ()) {
                        this.setPjValidators();
                    }

                    const beneficiarAddress = beneficiar.addresses && beneficiar.addresses.length ? beneficiar.addresses[0] : '';
                    if (beneficiarAddress) {
                        this.addressForm.patchValue({ ...beneficiarAddress, judet: Number(beneficiarAddress.judet) });
                    }
                },
                error: (err: any) => {
                    console.log(err);
                }
            });

        this.showBeneficiarForm = true;
        this.isUpdate = true;

        this.beneficiarType?.disable();
        this.scrollToBeneficiarTypeField();
    }

    convertExpiryDate() {
        if (this.expiryDate?.value) {
            const currentExpiryDate = this.expiryDate?.value;
            const convertedExpiryDate = moment(currentExpiryDate).format('YYYY-MM-DD');
            this.expiryDate?.setValue(convertedExpiryDate);
        }
    }

    addBeneficiar(): void {
        this.convertExpiryDate();

        if (!this.isFormValid()) {
            return;
        }

        console.log(this.beneficiarForm.getRawValue());

        this.isLoading = true;
        this.beneficiarService.addBeneficiar(this.beneficiarForm.getRawValue())
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: (res: any) => {
                    this.toastService.openToast({ title: this.successTitle, message: 'Beneficiarul a fost adăugat cu succes.', type: this.successIcon })
                    this.imputernicireFormArray?.clear();
                    this.toggleBeneficiarForm();
                    this.fetchBeneficiars()
                },
                error: (err: any) => {
                    console.log(err);
                    this.notificationService.handleHttpError(err.error);
                }
            })
    }

    updateBeneficiar(): void {
        this.convertExpiryDate();

        if (!this.isFormValid()) {
            return;
        }

        console.log(this.beneficiarForm.getRawValue());

        this.isLoading = true;
        this.beneficiarService.updateBeneficiar(this.beneficiarForm.getRawValue())
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: (res: any) => {
                    this.toastService.openToast({ title: this.successTitle, message: 'Beneficiarul a fost modificat cu succes.', type: this.successIcon })
                    this.imputernicireFormArray?.clear();
                    this.toggleBeneficiarForm();
                    this.isUpdate = false;
                    this.fetchBeneficiars()
                },
                error: (err: any) => {
                    console.log(err);
                    this.notificationService.handleHttpError(err.error);
                }
            });
    }

    removeBeneficiar(id: number): void {
        this.isLoading = true;
        this.beneficiarService.removeBeneficiar(id)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: (res: any) => {
                    console.log(res);
                    this.toastService.openToast({ title: this.successTitle, message: 'Beneficiarul a fost șters cu succes.', type: this.successIcon });
                    this.fetchBeneficiars();
                },
                error: (err: any) => {
                    console.log(err);
                    this.notificationService.handleHttpError(err.error);
                }
            });
    }

    getAddressString(address: Address) {
        return this.userService.generateAddressString(address);
    }

    compareAddresses(address1: Address, address2: Address): boolean {
        return address1 && address2 ? address1.id === address2.id : address1 === address2;
    }

    setUploading(key: string, value: boolean) {
        const desiredDocument = this.uploadDocs.find((doc) => doc.key === key);
        if (desiredDocument) {
            desiredDocument.uploading = value;
        }
    }

    getUploading(key: string) {
        const desiredDocument = this.uploadDocs.find((doc) => doc.key === key);
        if (desiredDocument) {
            return desiredDocument.uploading;
        } else {
            console.log('Document not found : ', key);
        }
        return false;
    }

    async onFileSelected(event: Event, key: string) {
        const selectedFiles = (event.target as HTMLInputElement).files;
        if (!selectedFiles) {
            return;
        }

        const uploadPromises = Array.from(selectedFiles).map(async (file) => {
            this.setUploading('imputernicire', true)
            const formData = new FormData();
            formData.append('file', file);

            const subscription = this.authService.fileUpload(formData).subscribe({
                next: (response: any) => {
                    this.toastService.openToast({ title: this.successTitle, message: 'Fișierul dvs a fost adăugat cu succes.', type: this.successIcon });

                    const fileGroup = this.fb.group({
                        filename: [response.data.filename],
                        original_name: [response.data.original_name],
                        ext: [response.data.ext],
                    });

                    this.imputernicireFormArray.push(fileGroup);
                },
                error: (error: any) => {
                    this.setUploading('imputernicire', false)
                    console.error(`Error uploading file ${file.name}:`, error);
                    this.notificationService.warningSwal(this.errorTitle, this.generalErrorMessage, this.errorIcon);
                },
                complete: () => {
                    subscription.unsubscribe();
                    this.setUploading('imputernicire', false);
                },
            });
        });

        try {
            await Promise.all(uploadPromises);
        } catch (error) {
            console.error(error);
        }
    }

    deleteUploadedFile(fileName: string) {
        const indexToDelete = this.imputernicireFormArray.controls.findIndex((control: any) => {
            const filenameControl = control.get('filename') as FormControl;
            return filenameControl.value === fileName;
        });

        if (indexToDelete >= 0) {
            this.imputernicireFormArray.removeAt(indexToDelete);
        }
    }

    isFormValid() {
        this.beneficiarForm.markAllAsTouched();
        this.beneficiarForm.updateValueAndValidity();

        return this.beneficiarForm.valid;
    }

    onBeneficiarTypeChange(bType: string) {
        console.log(bType);

        if (bType == '1') {
            this.setPfValidators();
        }
        if (bType == '2') {
            this.setPjValidators();
        }
    }

    scrollToBeneficiarTypeField() {
        if (this.topBeneficiarForm && this.topBeneficiarForm.nativeElement) {
            this.topBeneficiarForm.nativeElement.parentElement.scrollIntoView({ behavior: "smooth" });
        }
    }

    normalizeString(value: string): string {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    filterJudete(searchTerm: string): Observable<any[]> {
        const normalizedSearchTerm = this.normalizeString(searchTerm);

        const filtered = this.judete.filter(judet =>
            this.normalizeString(judet.name).includes(normalizedSearchTerm)
        );

        return of(filtered);
    }

    onJudetSelected(selectedJudet: any) {
        this.judetSelected = !!selectedJudet;
        this.localitatiControl.patchValue('');
        this.addressForm.get('oras_id')?.patchValue('');
        this.addressForm.get('oras')?.patchValue('');
    }

    fetchLocalitati(localitate: string) {
        let judet = this.judetSelected ? this.addressForm.get('judet')?.value : undefined;
        if (localitate.length >= 2) {
            this.isLocalitatiLoading = true;
            this.commonService.getLocalitatiActive(judet, localitate).subscribe({
                next: (response: any) => {
                    this.localitati = response.data;
                    this.isLocalitatiLoading = false;
                    this.localitatiLoadComplete = true;
                    this.showLocalitatiMessage = this.localitati.length === 0;
                },
                error: (err: any) => {
                    this.isLocalitatiLoading = false;
                    this.showLocalitatiMessage = true;
                    this.localitatiLoadComplete = true;
                    console.log(err);
                }
            });
        } else {
            this.localitati = [];
            this.showLocalitatiMessage = true;
            this.localitatiLoadComplete = false;
        }
    }

    onLocalitateSelected(localitateId: any) {
        const selectedLocalitate = this.localitati.find(localitate => localitate.id === localitateId);

        if (selectedLocalitate) {
            this.addressForm.get('oras')?.patchValue(selectedLocalitate.name);
        }
    }
}
