import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedLinksEnum, PublicLinksEnum } from '../../models';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs';
import { helper } from 'src/environments/helper';


export enum LINKS {
    solicitariDgitl = 'public/solicitari/dgitl',
    solicitariUrbanism = 'public/solicitari/urbanism',
}

@Component({
    selector: 'app-acasa',
    templateUrl: './acasa.component.html',
    styleUrls: ['./acasa.component.scss']
})
export class AcasaComponent {
    platformName = environment.config.platformName;
    public production: boolean = environment.production;
    public portalLive: boolean = environment.portalLive;
    public taxAllowed: boolean = environment.taxAllowed;
    publicLinks = PublicLinksEnum;
    loggedLinks = LoggedLinksEnum;
    articleList: any = [];
    isLoading = false;
    helper = helper;

    constructor(
        private router: Router,
        private authService: AuthService,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.getArticles();
    }

    navigateTo(route: string) {
        this.router.navigate([route]);
        console.log(route);
    }

    navigateToNewTab(route: string) {
        window.open(route, '_blank');
    }

    getArticles() {
        this.isLoading = true;
        this.authService.getArticles(0)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error)
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.articleList = response.data.records;
                            this.isLoading = false;
                            return;
                        }
                    } else {
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(environment.config.customNotifications.headers.error, errorMessage, environment.config.customNotifications.icons.error);
                    }
                },
                error: (res: any) => {
                    this.notificationService.handleHttpError(res.error);
                }
            });
    }

    formatDate(dateString: string): string {
        const [datePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('/');

        const dateObj = new Date(Number(year), Number(month) - 1, Number(day));

        const monthName = [
            'IANUARIE', 'FEBRUARIE', 'MARTIE', 'APRILIE', 'MAI', 'IUNIE',
            'IULIE', 'AUGUST', 'SEPTEMBRIE', 'OCTOMBRIE', 'NOIEMBRIE', 'DECEMBRIE'
        ];

        return `${day} ${monthName[dateObj.getMonth()]} ${year}`;
    }

    navigateToArticle(articleId: string) {
        this.router.navigate(['/noutati', articleId]);
    }

    isAuthenticated() {
        return this.authService.isAuthenticated();
    }
}
