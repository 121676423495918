import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeclaratiiComponent } from './declaratii.component';

const routes: Routes = [
    {
        path: ':institution',
        component: DeclaratiiComponent
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class DeclaratiiRoutingModule { }
