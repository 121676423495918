<div class="beneficiar-container container-fluid p-0">
    <div class="d-flex align-items-center mr-auto w-auto justify-content-between mb-3">
        <div class="d-flex align-content-center flex-wrap align-items-center">
            <h6 class="m-0 p-0">Beneficiari</h6>
            <mat-spinner diameter="32" class="ms-2" *ngIf="isLoading"></mat-spinner>
        </div>

        <button (click)="toggleBeneficiarForm()" *ngIf="!showBeneficiarForm">
            <img class="btn-img btn-blue btn-blue-opacity" src="assets/icons/add.svg" alt="" /> ADĂUGAȚI
        </button>
    </div>

    <div class="row m-0" *ngIf="!showBeneficiarForm">
        <mat-expansion-panel class="p-0" *ngIf="beneficiariPF.length !== 0">
            <mat-expansion-panel-header>
                <mat-panel-title> Persoane fizice</mat-panel-title>
            </mat-expansion-panel-header>

            <mat-card *ngFor="let beneficiar of beneficiariPF" class="justify-content-between mb-1">
                <!-- Display beneficiar persoane fizice details -->
                <div class="row m-0 w-100">
                    <div class="col-md-6">
                        <div>
                            <p class="title">Nume</p>
                            <p class="line">{{beneficiar.nume}}</p>
                        </div>
                        <div>
                            <p class="title">Prenume</p>
                            <p class="line">{{beneficiar.prenume}}</p>
                        </div>
                        <div>
                            <p class="title">CNP</p>
                            <p class="line">{{beneficiar.cnp_cui}}</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <p class="title">Tip beneficiar</p>
                            <p class="line">{{beneficiarTypeString(beneficiar.beneficiar_type)}}</p>
                        </div>
                        <div>
                            <p class="title">CI Serie</p>
                            <p class="line">{{beneficiar.ci_serie || 'n/a'}}</p>
                        </div>
                        <div>
                            <p class="title">CI Număr</p>
                            <p class="line">{{beneficiar.ci_numar || 'n/a'}}</p>
                        </div>
                    </div>
                </div>
                <div class='column justify-content-end'>
                    <button (click)="showUpdateBeneficiar(beneficiar)" class="w-100">
                        <img class="btn-img btn-blue-opacity" src="assets/icons/edit.svg" alt="" /> MODIFICAȚI
                    </button>
                    <button (click)="removeBeneficiar(beneficiar.id)" class="w-100">
                        <img class="btn-img" src="assets/icons/delete.svg" alt="" /> ȘTERGEȚI
                    </button>
                </div>
            </mat-card>
        </mat-expansion-panel>

        <div class="mt-3"></div>

        <mat-expansion-panel class="p-0" *ngIf="beneficiariPJ.length !== 0">
            <mat-expansion-panel-header>
                <mat-panel-title> Persoane juridice</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card *ngFor="let beneficiar of beneficiariPJ" class="justify-content-between mb-1">
                <!-- Display beneficiar persoane juridice details -->
                <div class="row m-0 w-100">
                    <div class="col-md-6">
                        <div>
                            <p class="title">Denumire firmă</p>
                            <p class="line">{{beneficiar.denumire || 'n/a'}}</p>
                        </div>
                        <div>
                            <p class="title">CUI</p>
                            <p class="line">{{beneficiar.cnp_cui || 'n/a'}}</p>
                        </div>
                        <div>
                            <p class="title">Registrul comerțului</p>
                            <p class="line">{{beneficiar.reg_com || 'n/a'}}</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <p class="title">Tip beneficiar</p>
                            <p class="line">{{beneficiarTypeString(beneficiar.beneficiar_type)}}</p>
                        </div>
                    </div>
                </div>
                <div class='column justify-content-end'>
                    <button (click)="showUpdateBeneficiar(beneficiar)" class="w-100">
                        <img class="btn-img btn-blue-opacity" src="assets/icons/edit.svg" alt="" /> MODIFICAȚI
                    </button>
                    <button (click)="removeBeneficiar(beneficiar.id)" class="w-100">
                        <img class="btn-img" src="assets/icons/delete.svg" alt="" /> ȘTERGEȚI
                    </button>
                </div>
            </mat-card>
        </mat-expansion-panel>
    </div>


    <div #topBeneficiarForm></div>
    <form class="" [formGroup]="beneficiarForm" *ngIf="showBeneficiarForm">
        <div class="row">
            <mat-form-field appearance="fill">
                <mat-label>Tip beneficiar</mat-label>
                <mat-select formControlName="beneficiar_type" (selectionChange)="onBeneficiarTypeChange($event.value)">
                    <mat-option *ngFor="let type of beneficiarTypes" [value]="type.value">
                        {{ type.label }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="beneficiarType && (beneficiarType.dirty || beneficiarType.touched) && beneficiarType.hasError('notDefault')">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="isPF()">
            <div class="row">
                <!-- nume -->
                <mat-form-field appearance="fill" class="col-md-6">
                    <mat-label>Nume</mat-label>
                    <input formControlName="nume" matInput type="text" />
                    <mat-hint>Introduceți numele</mat-hint>
                    <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>

                <!-- prenume -->
                <mat-form-field appearance="fill" class="col-md-6">
                    <mat-label>Prenume</mat-label>
                    <input formControlName="prenume" matInput type="text" />
                    <mat-hint>Introduceți prenumele</mat-hint>
                    <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="row mt-md-3">
                <!-- ci_serie -->
                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>CI Serie</mat-label>
                    <input formControlName="ci_serie" matInput type="text" />
                    <mat-error *ngIf="ciSerie && (ciSerie.dirty || ciSerie.touched) && ciSerie.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>

                <!-- ci_numar -->
                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>CI Număr</mat-label>
                    <input formControlName="ci_numar" matInput type="text" />
                    <mat-error *ngIf="ciNumar && (ciNumar.dirty || ciNumar.touched) && ciNumar.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>

                <!-- ci_type -->
                <mat-form-field class="col-md-3">
                    <mat-label>Tip CI</mat-label>
                    <mat-select formControlName="ci_type">
                        <mat-option *ngFor="let type of ciTypes" [value]="type.value">
                            {{ type.label }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ciType && (ciType.dirty || ciType.touched) && ciType.hasError('notDefault')">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>

                <!-- ci_emitent -->
                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>CI Emitent</mat-label>
                    <input formControlName="ci_emitent" matInput type="text" />
                    <mat-error
                        *ngIf="ciEmitent && (ciEmitent.dirty || ciEmitent.touched) && ciEmitent.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="row mt-md-3">
                <!-- cnp / cui -->
                <mat-form-field appearance="fill" class="col-md-6">
                    <mat-label>CNP</mat-label>
                    <input formControlName="cnp_cui" matInput type="text" />
                    <mat-hint>Introduceți CNP</mat-hint>
                    <mat-error *ngIf="cnpCui && (cnpCui.dirty || cnpCui.touched) && cnpCui.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="col-md-6">
                    <mat-label>Dată expirare</mat-label>
                    <input matInput [min]="minExpiryDate" [matDatepicker]="pickerExpiryDate"
                        formControlName="expiry_date" readonly (focus)="pickerExpiryDate.open()" />
                    <mat-datepicker-toggle matIconSuffix [for]="pickerExpiryDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerExpiryDate></mat-datepicker>
                    <mat-hint>ZZ/LL/AAAA</mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="row mt-md-3" *ngIf="isPJ()">
            <!-- denumire firma -->
            <mat-form-field appearance="fill" class="col-md-4">
                <mat-label>Denumire Firmă</mat-label>
                <input formControlName="denumire" matInput type="text" />
                <mat-hint>Introduceți numele firmei</mat-hint>
                <mat-error
                    *ngIf="denumireFirma && (denumireFirma.dirty || denumireFirma.touched) && denumireFirma.hasError('required')">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-md-4">
                <mat-label>CUI/CIF</mat-label>
                <input formControlName="cnp_cui" matInput type="text" />
                <mat-hint>Introduceți CUI/CIF</mat-hint>
                <mat-error *ngIf="cnpCui && (cnpCui.dirty || cnpCui.touched) && cnpCui.hasError('required')">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>

            <!-- nr registru comertului -->
            <mat-form-field appearance="fill" class="col-md-4">
                <mat-label>Registrul Comerțului</mat-label>
                <input formControlName="reg_com" matInput type="text" />
                <mat-hint>Introduceți număr registrul comerțului</mat-hint>
            </mat-form-field>
        </div>

        <div class="mt-md-3" [formGroup]="addressForm" *ngIf="isPJ() || isPF()">
            <!-- addresses picked from the user addresses
            <mat-form-field class="col-md-6">
                <mat-label>Adresă</mat-label>
                <mat-select formControlName="address" [compareWith]="compareAddresses">
                    <mat-option *ngFor="let address of addresses" [value]="address">
                        {{ getAddressString(address) }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!beneficiarForm.controls['address'].valid && beneficiarForm.controls['address'].touched">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field> -->
            <div class="row">
                <mat-form-field appearance="fill">
                    <mat-label>Tip Adresă</mat-label>
                    <mat-select matSelect formControlName="type" required>
                        <mat-option [value]="-1">-- Alegeți --</mat-option>
                        <mat-option [value]="1">Domiciliu</mat-option>
                        <mat-option [value]="2" *ngIf="isPJ()">Sediu Social</mat-option>
                        <mat-option [value]="3">Reședință</mat-option>
                    </mat-select>
                    <mat-hint>Alegeți tipul adresei</mat-hint>
                    <mat-error
                        *ngIf="typeOfAddress && (typeOfAddress.dirty || typeOfAddress.touched) && typeOfAddress.hasError('notDefault')">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="col-md-4">
                    <mat-label>Județ</mat-label>
                    <mat-select formControlName="judet" required="required" (selectionChange)="onJudetSelected($event)">
                        <mat-form-field class="px-2">
                            <input matInput [formControl]="judeteControl" />
                        </mat-form-field>

                        <mat-option *ngIf="filteredJudete.length === 0 && judeteControl.value" disabled>
                            <span>No results found</span>
                        </mat-option>

                        <mat-option value="-1">-- Alegeți --</mat-option>
                        <mat-option *ngFor="let judet of filteredJudete" [value]="judet.id">
                            {{ judet.name }}
                        </mat-option>
                    </mat-select>

                    <mat-hint>Alegeți județul</mat-hint>
                    <mat-error *ngIf="judet && (judet.dirty || judet.touched) && judet.hasError('notDefault')">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-md-4">
                    <mat-label>Municipiu/Oraș/Comună</mat-label>
                    <mat-select formControlName="oras_id" required="required"
                        (selectionChange)="onLocalitateSelected($event.value)">
                        <mat-form-field class="px-2">
                            <input matInput [formControl]="localitatiControl" />
                        </mat-form-field>

                        <mat-option *ngIf="showLocalitatiMessage" disabled>
                            <span *ngIf="!localitatiControl.value || localitatiControl.value.length < 2">Vă rugăm să
                                introduceți 2 caractere sau mai multe</span>
                            <span *ngIf="isLocalitatiLoading && localitatiControl.value.length >= 2">Searching...</span>
                            <span
                                *ngIf="!isLocalitatiLoading && localitatiLoadComplete && localitati.length === 0 && localitatiControl.value && localitatiControl.value.length >= 2">No
                                results found</span>
                        </mat-option>

                        <mat-option *ngFor="let localitate of localitati" [value]="localitate.id">
                            {{ localitate.name }}
                        </mat-option>
                    </mat-select>

                    <mat-hint>Introduceți orașul</mat-hint>
                    <mat-error *ngIf="oras && (oras.dirty || oras.touched) && oras.hasError('required')">
                        <strong>Câmp obligatoriu</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-md-4">
                    <mat-label>Sector</mat-label>
                    <mat-select matSelect formControlName="sector">
                        <mat-option [value]="null">-- Alegeți --</mat-option>
                        <mat-option [value]="1">Sector 1</mat-option>
                        <mat-option [value]="2">Sector 2</mat-option>
                        <mat-option [value]="3">Sector 3</mat-option>
                        <mat-option [value]="4">Sector 4</mat-option>
                        <mat-option [value]="5">Sector 5</mat-option>
                        <mat-option [value]="6">Sector 6</mat-option>
                    </mat-select>
                    <mat-hint>Alegeți sectorul</mat-hint>
                    <!-- <mat-error *ngIf="addressForm.controls['sector']?.hasError('required')">
                        Câmp obligatoriu
                    </mat-error> -->
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="col-md-9">
                    <mat-label>Strada</mat-label>
                    <input formControlName="strada" matInput type="text" required="required" />
                    <mat-error *ngIf="strada && (strada.dirty || strada.touched) && strada.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="strada && (strada.dirty || strada.touched) && strada.hasError('minlength')">
                        Minim 2 caractere
                    </mat-error>
                    <mat-error *ngIf="strada && (strada.dirty || strada.touched) && strada.hasError('maxlength')">
                        Max {{env.config.validatorsAccrossApp.maxStringLength}} caractere
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Număr</mat-label>
                    <input formControlName="numar" matInput type="text" required="required" />
                    <mat-error *ngIf="numar && (numar.dirty || numar.touched) && numar.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="numar && (numar.dirty || numar.touched) && numar.hasError('minlength')">
                        Minim 2 caractere
                    </mat-error>
                    <mat-error *ngIf="numar && (numar.dirty || numar.touched) && numar.hasError('maxlength')">
                        Max 50 caractere
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Bloc</mat-label>
                    <input formControlName="bloc" matInput type="text" />
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Scara</mat-label>
                    <input formControlName="scara" matInput type="text" />
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Etaj</mat-label>
                    <input formControlName="etaj" matInput type="text" />
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-md-3">
                    <mat-label>Apartament</mat-label>
                    <input formControlName="apartament" matInput type="text" />
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill">
                    <mat-label>Observații</mat-label>
                    <textarea formControlName="observatii" matInput></textarea>
                    <mat-hint>Introduceți observații</mat-hint>
                </mat-form-field>
            </div>

        </div>


        <!-- upload imputernicire -->
        <div class="upload-container">
            <div class="uploaded-files">
                <p>
                    Împuternicire
                </p>
                <ng-container>
                    <div class="uploaded-files-container">
                        <div *ngFor="let fg of imputernicireFormArray.controls"
                            class="success-upload docs-list-uploaded-container">
                            <p>{{ fg.get('original_name')?.value }}</p>
                            <button type="button"
                                (click)="fileInput.value = ''; deleteUploadedFile(fg.get('filename')?.value)">
                                <img class="delete-icon" src="assets/icons/delete.svg" />
                            </button>
                        </div>
                    </div>
                </ng-container>
                <mat-spinner diameter="32" *ngIf="getUploading('imputernicire')"></mat-spinner>
            </div>
            <div>
                <label for="imputernicire">
                    <button class="w-100">
                        <img class="btn-img btn-blue btn-blue-opacity" src="assets/icons/upload.svg" alt="" /> Încărcați
                        <input #fileInput type="file" id="imputernicire"
                            (change)="onFileSelected($event, 'imputernicire')" class="upload-input" multiple />
                    </button>
                </label>
            </div>
        </div>




        <!-- buttons -->
        <div class="mt-3 action-container">
            <button type="button" (click)="toggleBeneficiarForm()">
                ÎNCHIDE
            </button>

            <button type="button" (click)="addBeneficiar()" *ngIf="!isUpdate">
                SALVEAZĂ
            </button>

            <button type="button" (click)="updateBeneficiar()" *ngIf="isUpdate">
                ACTUALIZARE INFORMAȚII
            </button>
        </div>

    </form>

</div>