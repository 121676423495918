import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermeniConditiiModalComponent } from './termeni-conditii-modal.component';
import { MaterialModule } from 'src/app/material.module';



@NgModule({
    declarations: [
        TermeniConditiiModalComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
    ],
    exports: [TermeniConditiiModalComponent],
})
export class TermeniConditiiModalModule { }
