<div class="home-card">
    <div class="home-card-main">
        <div class="home-card-welcome">
            <img src="assets/images/welcome.svg" alt="" />
            <mat-card class="home-card-welcome__card">
                <mat-card-header class="home-card-welcome__header">
                    Bine ați venit!
                </mat-card-header>
                <mat-card-content class="home-card-welcome__content">
                    În cadrul acestui portal puteți să vă gestionați solicitările din cadrul Platformei Online a Primăriei Sectorului 4
                </mat-card-content>
            </mat-card>
        </div>

        <div class="home-card-applications">
            <div class="home-card-application" routerLink="/dashboard/solicitari">
                <img class="btn-img" src="assets/icons/docs.svg" alt="Solicitarile mele" />
                <div class="home-card-application-title">solicitările mele</div>
            </div>

            <div class="home-card-application" routerLink="/dashboard/payments">
                <img class="btn-img" src="assets/icons/wallet.svg" alt="Plati Online" />
                <div class="home-card-application-title">Plăți ONLINE</div>
            </div>

            <!-- <div class="home-card-application" routerLink="/dashboard/utils">
                <img class="btn-img" src="assets/icons/icon-addcard.svg" alt="Utile" />
                <div class="home-card-application-title">UTILE</div>
            </div> -->
        </div>
    </div>

    <div class="home-card-announcements">
        <app-announcements [slug]="pageSlug"></app-announcements>
    </div>

    <!-- aici adaugam similar ca pe home -->
    <div class="section1">
        <div
            class="section1-content d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center">
            <span>Programare pentru Asistență - Biroul de Asistență</span>
            <button class="mt-lg-0 mt-3 d-flex justify-content-center align-items-center" type="button"
                (click)="navigateToNewTab('https://s4programare.netlify.app')">PROGRAMAȚI-VĂ!</button>
        </div>
    </div>

    <div class="section2 container-fluid">
        <div class="section2-content d-flex gap-3 flex-column flex-xl-row">
            <div class="row">
                <div class="section2-content-card d-flex align-items-start me-2 mb-2">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <span>Depune o cerere pentru</span>
                        <img src="assets/icons/arrow-big.svg" />
                    </div>
                </div>
            </div>

            <div class="d-flex flex-wrap w-100">
                <div (click)="navigateTo(loggedLinks.solicitariUrbanismPlatform)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/Stema-Bucuresti_line.svg" style="height: 80px;" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Generală de Urbanism și Amenajarea Teritoriului Sector 4</span>
                        <!-- <span class="tag">#TaxeȘiImpozite</span> -->
                    </div>
                </div>

                <div (click)="navigateTo(loggedLinks.solicitariDgitlAccount)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgitl4.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Generală de Impozite și Taxe Locale</span>
                        <!-- <span class="tag">#TaxeȘiImpozite</span> -->
                    </div>
                </div>

                <div (click)="navigateToNewTab('https://mobilitateurbana4.ro/registratura-online')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/mobilitate-urbana.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Mobilitate Urbană</span>
                        <!-- <span class="tag">#MobilitateUrbană</span> -->
                    </div>
                </div>

                <div (click)="navigateTo(loggedLinks.solicitariAutorizariComerciale)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/Stema-Bucuresti_line.svg" style="height: 80px;" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Serviciul Autorizări Comerciale</span>
                        <!-- <span class="tag">#AutorizăriComerciale</span> -->
                    </div>
                </div>

                <div (click)="navigateTo(loggedLinks.programariAccount)"
                    class="section2-content-cards d-flex flex-column justify-content-between col-lg-4 ms-1 me-1">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgep.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Generală de Evidență a Persoanelor</span>
                        <!-- <span class="tag">#AutorizăriComerciale</span> -->
                    </div>
                </div>
                <div *ngIf="!isProduction()" (click)="navigateTo(loggedLinks.solicitariAsistentaSociala)"
                    class="section2-content-cards d-flex flex-column justify-content-between col-lg-4 ms-1 me-1">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgep.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Generală de Asistență Socială și Protecția Copilului</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section2 container-fluid">
        <div class="section2-content d-flex gap-3 flex-column flex-xl-row"
            style="background: linear-gradient(0deg, #FFD180 0%, #FFD180 100%), #FE7800;">
            <div class="row">
                <div class="section2-content-card d-flex align-items-start me-1 mb-2">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <span>Plăți Online</span>
                        <img src="assets/icons/arrow-big.svg" />
                    </div>
                </div>
            </div>

            <div class="d-flex flex-wrap w-100">
                <div class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2"
                    (click)="navigateTo(loggedLinks.platiOnline)">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/taxe-nedebitate.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Taxe și Impozite</span>
                        <!-- <span class="tag">#ImpoziteȘiTaxe</span> -->
                    </div>
                </div>

                <div (click)="navigateTo(loggedLinks.programariAccount + '/plati-online')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/evidenta-populatiei.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Evidența Populației</span>
                        <!-- <span class="tag">#EvidențaPopulației</span> -->
                    </div>
                </div>

                <div (click)="navigateToNewTab('https://www.mobilitateurbana4.ro/plati/')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/parcari.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Parcări</span>
                        <!-- <span class="tag">#MobilitateUrbană</span> -->
                    </div>
                </div>

                <div class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2"
                    (click)="navigateTo(loggedLinks.platiAlteTaxeAmenzi)">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/taxe-nedebitate.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Alte Taxe și Amenzi</span>
                        <!-- <span class="tag">#ImpoziteȘiTaxe</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section2 container-fluid">
        <div class="section2-content d-flex gap-3 flex-column flex-xl-row"
            style="background: linear-gradient(0deg, rgba(105, 240, 174, 0.16) 0%, rgba(105, 240, 174, 0.16) 100%), rgba(105, 240, 174, 0.70);">
            <div class="row">
                <div class="section2-content-card d-flex align-items-start col-lg-3 me-1 mb-2">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <span>Link-uri rapide</span>
                        <img src="assets/icons/arrow-big.svg" />
                    </div>
                </div>
            </div>

            <div class="d-flex flex-wrap w-100">
                <div (click)="navigateToNewTab('https://ps4.ro/monitorul-oficial-local/hotararile-autoritatii-deliberative/hotarari/')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/hotarari-consiliu.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Hotărâri consiliu</span>
                        <!-- <span class="tag">#ConsiliulLocal4</span> -->
                    </div>
                </div>

                <div (click)="navigateToNewTab('https://ps4.ro/monitorul-oficial-local/dispozitiile-autoritatii-executive/dispozitii/')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dispozitii.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Dispoziții</span>
                        <!-- <span class="tag">#PrimăriaSectorului4</span> -->
                    </div>
                </div>

                <div (click)="navigateToNewTab('https://taxeimpozite4.ro')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgitl4.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Taxe și Impozite</span>
                        <!-- <span class="tag">#TaxeȘiImpozite</span> -->
                    </div>
                </div>

                <div (click)="navigateToNewTab('https://mobilitateurbana.ps4.ro')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/parcari.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Parcări</span>
                        <!-- <span class="tag">#MobilitateUrbană</span> -->
                    </div>
                </div>

                <div (click)="navigateToNewTab('https://dgaspc.ps4.ro')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgaspc.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Asistență Socială și Protecția Copilului</span>
                        <!-- <span class="tag">#DGASPCS4</span> -->
                    </div>
                </div>

                <div (click)="navigateToNewTab('https://deps4.ro')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div
                        class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgep.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Evidența Persoanelor</span>
                        <!-- <span class="tag">#EvidențaPersoanelor</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>