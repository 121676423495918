<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="cariera-container">
    <div class="cariera-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="cariera-welcome-card-content d-flex flex-column gap-4 justify-content-start align-self-stretch">
            <span class="institution">
                {{institution === 'dgaspc' ? 'Direcția Generală de Asistență Socială și Protecția Copilului':
                ''}}
            </span>
            <span class="subtitle">Carieră</span>
        </div>
    </div>

    <div class="cariera-content d-flex flex-column align-items-start align-self-stretch">
        <div class="cariera-content-container d-flex flex-column align-self-stretch">
            <div *ngFor="let item of documente">
                <ng-container *ngIf="getAllDocuments(item.documents).length">
                    <h2>{{ item.name }}</h2>

                    <div *ngFor="let doc of getAllDocuments(item.documents)">
                        <p (click)="downloadFile(doc.id)">
                            <i class="fa-solid fa-cloud-arrow-down"></i>
                            {{ doc.web_name }}
                        </p>
                        <mat-divider class="my-3"></mat-divider>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>

<div class="section-spinner position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
    *ngIf="isLoading">
    <div class="spinner">
        <mat-spinner></mat-spinner>
        <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
    </div>
</div>