<div class="settings-card">
    <div class="settings-card__header">
        <img src="assets/images/Group 24.svg" alt="" />
        <div class="settings-card__header-headline">
            <h2>Setările dumneavoastră</h2>
            <h4>{{ 'Platforma Online - ' + platformName }}</h4>
        </div>
    </div>
    <button (click)="openSolicitare()">SUPORT TEHNIC</button>
</div>

<div class="container-fluid p-4 m-0 pt-0">
    <div class="row justify-content-between">
        <div class="col-md-12">
            <mat-card>
                <div>
                    <h6>Informații generale</h6>
                    <div class="settings-container__right-header-content action-container">
                        <div>
                            <div>
                                <p class="title">Versiune Aplicație</p>
                                <p class="line">
                                    <strong>v1.0.0</strong>
                                </p>
                            </div>
                        </div>
                        <button (click)="openTermsModal(termsSlug)">TERMENI ȘI CONDIȚII</button>
                    </div>
                </div>
            </mat-card>
        </div>

    </div>
    <div class="row justify-content-between pt-3">

        <div class="col-md-6">
            <mat-card>
                <div class="d-flex align-items-center mr-auto w-auto justify-content-between mb-3">
                    <div class="d-flex align-content-center flex-wrap align-items-center">
                        <h6 class="m-0 p-0">Informații personale</h6>
                        <mat-spinner diameter="32" class="ms-2" *ngIf="loadingAccount"></mat-spinner>
                    </div>

                    <button class="btn-outline" (click)="toggleForm()" *ngIf="!updateFormShow">
                        <img class="btn-img btn-blue btn-blue-opacity" src="assets/icons/edit.svg" alt="" /> MODIFICAȚI
                    </button>
                </div>


                <div *ngIf="!updateFormShow">
                    <div class="row justify-content-between p-2">
                        <strong class="w-auto">Nume</strong>
                        <span class="w-auto">{{ user.nume }}</span>
                    </div>
                    <hr class="m-0">
                    <div class="row justify-content-between p-2">
                        <strong class="w-auto">Prenume</strong>
                        <span class="w-auto">{{ user.prenume }}</span>
                    </div>
                    <hr class="m-0">
                    <div class="row justify-content-between p-2">
                        <strong class="w-auto">Email</strong>
                        <span class="w-auto">{{ user.email }}</span>
                    </div>
                    <hr class="m-0">
                    <div class="row justify-content-between p-2">
                        <strong class="w-auto">CI</strong>
                        <span class="w-auto">{{ user.ci_serie || "n/a" }} - {{ user.ci_numar || "n/a" }}</span>
                    </div>
                    <hr class="m-0">
                    <div class="row justify-content-between p-2">
                        <strong class="w-auto">CNP/CUI</strong>
                        <span class="w-auto">{{ user.cnp }}</span>
                    </div>

                    <ng-container *ngIf="user.f_nume !== '' && userType == 2">
                        <hr class="m-0">
                        <div class="row justify-content-between p-2">
                            <strong class="w-auto">Denumire Companie</strong>
                            <span class="w-auto">{{ user.f_nume }}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="user.f_reg_com !== '' && userType == 2">
                        <hr class="m-0">
                        <div class="row justify-content-between p-2">
                            <strong class="w-auto">Registrul Comerțului</strong>
                            <span class="w-auto">{{ user.f_reg_com || "n/a" }}</span>
                        </div>
                    </ng-container>

                    <hr class="m-0">
                    <div class="row justify-content-between p-2">
                        <strong class="w-auto">Telefon</strong>
                        <span class="w-auto">{{ user.international_number }}</span>
                    </div>
                </div>

                <form class="settings-container__left-form" *ngIf="updateFormShow" [formGroup]="form" (ngSubmit)="updateAccount()">
                    <!-- add here the form inputs -->
                    <!-- nume -->
                    <mat-form-field appearance="fill">
                        <input formControlName="nume" matInput placeholder="Nume" type="text" required="required" />
                        <mat-hint>Introduceți numele</mat-hint>

                        <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('required')">
                            <strong>Câmp obligatoriu</strong>
                        </mat-error>
                        <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('minlength')">
                            <strong>Minim {{env.config.validatorsAccrossApp.minStringLength}} caractere</strong>
                        </mat-error>
                        <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('maxlength')">
                            <strong>Max {{env.config.validatorsAccrossApp.maxStringLength}} caractere</strong>
                        </mat-error>
                    </mat-form-field>

                    <!-- prenume -->
                    <mat-form-field appearance="fill">
                        <input formControlName="prenume" matInput placeholder="Prenume" type="text" required="required" />
                        <mat-hint>Introduceți prenumele</mat-hint>

                        <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('required')">
                            <strong>Câmp obligatoriu</strong>
                        </mat-error>
                        <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('minlength')">
                            <strong>Minim {{env.config.validatorsAccrossApp.minStringLength}} caractere</strong>
                        </mat-error>
                        <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('maxlength')">
                            <strong>Max {{env.config.validatorsAccrossApp.maxStringLength}} caractere</strong>
                        </mat-error>
                    </mat-form-field>

                    <!-- ci_serie -->
                    <mat-form-field appearance="fill">
                        <input formControlName="ci_serie" matInput placeholder="CI Serie" type="text" />
                        <mat-hint>Introduceți serie CI</mat-hint>
                    </mat-form-field>

                    <!-- ci_numar -->
                    <mat-form-field appearance="fill">
                        <input formControlName="ci_numar" matInput placeholder="CI Numar" type="text" />
                        <mat-hint>Introduceți numărul CI</mat-hint>
                    </mat-form-field>

                    <ng-container *ngIf="userType == 2">
                        <!-- denumire firma -->
                        <mat-form-field appearance="fill">
                            <input formControlName="f_nume" matInput placeholder="Denumire Firma" type="text" required />
                            <mat-hint>Introduceți numele firmei</mat-hint>
                        </mat-form-field>

                        <!-- nr registru comertului -->
                        <mat-form-field appearance="fill">
                            <input formControlName="f_reg_com" matInput placeholder="Registrul Comertului" type="text" required />
                            <mat-hint>Introduceți număr registrul comerțului</mat-hint>
                        </mat-form-field>
                    </ng-container>

                    <!-- email -->
                    <mat-form-field appearance="fill">
                        <input formControlName="email" matInput placeholder="Email" type="email" />
                        <mat-hint>Introduceți adresa de email</mat-hint>
                    </mat-form-field>

                    <!-- telefon -->
                    <mat-form-field appearance="fill">
                        <button type="button" class="country-selector" mat-button matPrefix [matMenuTriggerFor]="menu">
                            <mat-icon class="expand-icon">expand_more</mat-icon>
                            {{ currentOption.flag }} {{ currentOption.dial_code }}
                        </button>
                        <mat-menu #menu="matMenu">
                            <button type="button" mat-menu-item (click)="changeCountry(optionRom)">
                                <span class="flag-wrapper">🇷🇴</span>
                                <span class="label-wrapper">
                                    {{ optionRom.name }} {{ optionRom.dial_code }}
                                </span>
                            </button>
                            <mat-divider class="divider"></mat-divider>
                            <button type="button" mat-menu-item *ngFor="let option of options" (click)="changeCountry(option)">
                                <span class="flag-wrapper">{{ option.flag }}</span>
                                <span class="label-wrapper">
                                    {{ option.name }} {{ option.dial_code }}
                                </span>
                            </button>
                        </mat-menu>
                        <input type="text" matInput formControlName="telefon" />
                        <mat-hint>Introduceți numărul de telefon</mat-hint>
                        <mat-error *ngIf="!form.controls['telefon'].valid && form.controls['telefon'].touched">
                            Camp obligatoriu
                        </mat-error>
                    </mat-form-field>

                    <!-- buttons -->
                    <div class="action-container act-container-responsive">
                        <button type="button" (click)="toggleForm()">
                            ÎNCHIDE
                        </button>

                        <button type="submit">
                            ACTUALIZARE INFORMAȚII
                        </button>
                    </div>
                </form>

            </mat-card>
        </div>

        <div class="col-md-6">
            <mat-card>
                <div class="d-flex align-content-center flex-wrap align-items-center mb-3">
                    <h6 class="m-0 p-0">Actualizare parolă</h6>
                    <mat-spinner diameter="32" class="ms-2" *ngIf="loadingPassword"></mat-spinner>
                </div>

                <form class="settings-container__right-form" [formGroup]="formPasswords" (ngSubmit)="onUpdatePassword()">
                    <mat-form-field appearance="fill">
                        <mat-label>Parola actuală</mat-label>
                        <input type="text" matInput required [type]="hideOldPass ? 'password' : 'text'" formControlName="old_password" />
                        <button type="button" mat-icon-button matSuffix (click)="hideOldPass = !hideOldPass" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hideOldPass">
                            <mat-icon>{{ hideOldPass ? "visibility_off" : "visibility" }}</mat-icon>
                        </button>
                        <mat-hint>Introduceți parola actuală</mat-hint>
                        <mat-error *ngIf="
							!formPasswords.controls['old_password'].valid &&
							formPasswords.controls['old_password'].touched
						">
                            Camp obligatoriu
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Parola</mat-label>
                        <input formControlName="password" matInput [errorStateMatcher]="matcher" required="required" [type]="hide ? 'password' : 'text'">
                        <mat-hint>Introduceți parola</mat-hint>
                        <mat-icon matSuffix (click)="hide = !hide">
                            {{hide ? 'visibility_off' : 'visibility'}}
                        </mat-icon>

                        <mat-error *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                        <mat-error *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('minlength')">
                            Minim {{env.config.validatorsAccrossApp.minPassword}} caractere
                        </mat-error>
                        <mat-error *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('maxlength')">
                            Max {{env.config.validatorsAccrossApp.maxPassword}} caractere
                        </mat-error>
                        <mat-error *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('noSpecialChar')">
                            Minim 1 caracter special
                        </mat-error>
                        <mat-error *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('noNumber')">
                            Minim 1 cifră
                        </mat-error>
                        <mat-error
                            *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('noUpperCase')">
                            Minim 1 literă mare
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="margin-top" appearance="fill">
                        <mat-label>Confirmați parola</mat-label>
                        <input formControlName="password_confirmation" matInput [errorStateMatcher]="matcher" required="required" [type]="hide_confirmation ? 'password' : 'text'">
                        <mat-hint>Reintroduceți parola</mat-hint>
                        <mat-icon matSuffix (click)="hide_confirmation = !hide_confirmation">
                            {{hide_confirmation ? 'visibility_off' : 'visibility'}}
                        </mat-icon>

                        <mat-error
                            *ngIf="formPasswords.get('password_confirmation')?.errors?.['required'] && (formPasswords.get('password_confirmation')?.dirty || formPasswords.get('password_confirmation')?.touched)">
                            Câmp obligatoriu
                        </mat-error>
                        <mat-error
                            *ngIf="formPasswords.get('password_confirmation')?.errors?.['mismatch'] && (formPasswords.get('password_confirmation')?.dirty || formPasswords.get('password_confirmation')?.touched)">
                            Parolele nu se potrivesc
                        </mat-error>
                    
                    </mat-form-field>
                    <br>
                    <div class="password-conditions-box">
                        <p>Parola trebuie să conțină:
                            <br /> 1. Minim {{env.config.validatorsAccrossApp.minPassword}} caractere
                            <br /> 2. Minim o literă mare
                            <br /> 3. Minim o literă mică
                            <br /> 4. Minim un caracter special
                            <br /> 5. Minim o cifră
                        </p>
                    </div>
                    <div class="action-container">
                        <button type="submit" class="action-btn w-100" [disabled]="!formPasswords.valid">
                            ACTUALIZAȚI PAROLA
                        </button>
                    </div>
                </form>
            </mat-card>
        </div>

    </div>

    <div class="row justify-content-between pt-3">

        <div class="col-md-6">
            <mat-card>
                <app-beneficiar class="me-2"></app-beneficiar>
            </mat-card>
        </div>
        <div class="col-md-6">
            <mat-card class="w-100">
                <div class="w-100 address-container">
                    <div class="d-flex align-items-center mr-auto w-auto justify-content-between mb-3">
                        <div class="d-flex align-content-center flex-wrap align-items-center">
                            <h6 class="m-0 p-0">Adrese</h6>
                            <mat-spinner diameter="32" class="ms-2" *ngIf="loadingAddresses"></mat-spinner>
                        </div>

                        <button class="btn-outline" (click)="toggleFormAddress()" *ngIf="!updateFormShowAddress">
                            <img class="btn-img btn-blue btn-blue-opacity" src="assets/icons/add.svg" alt="" />ADĂUGAȚI
                        </button>
                    </div>
                    <div *ngIf="!updateFormShowAddress">
                        <!-- not really using this  mat-select with address types. Can be uncommented if we want to use it in a address filtering.					
						<div class="settings-container__left-address-select">

							<mat-form-field class="settings-container__left-address-select-form">
								<mat-label>Selectați</mat-label>
								<mat-select [(value)]="selectedAddress">
									<mat-option *ngFor="let address of addresses" [value]="address.value">{{ address.viewValue }}
									</mat-option>
								</mat-select>
							</mat-form-field>

							<button class="btn-outline" (click)="toggleFormAddress()">
								<img class="btn-img btn-blue btn-blue-opacity" src="assets/icons/add.svg" alt="" />ADĂUGAȚI
							</button>
						</div> -->

                        <div class="smallHeader mb-3">
                            <h5 class="border-bottom">
                                <strong>Adresă Domiciliu </strong>
                            </h5>
                        </div>
                        <mat-card *ngFor="let row of listAddresses.domiciliu" class="justify-content-between mb-1 ">
                            <div class="row m-0 w-100 responsive-card">
                                <div class="col-sm-8">
                                    <p class="m-0">
                                        <strong>Județ:</strong> {{ row.judet_name }},
                                        <strong>Oraș:</strong> {{ row.oras }},
                                        <strong>Cod poștal:</strong> {{ row.cod_postal || "n/a" }},
                                        <strong>Sector:</strong> {{ row.sector || "n/a" }}
                                    </p>
                                    <p class="m-0">
                                        <strong>Strada:</strong> {{ row.strada }},
                                        <strong>Număr:</strong> {{ row.numar }}
                                        <span *ngIf="row.bloc !== null">,
                                            <strong>Bl.</strong>
                                            {{ row.bloc }}</span>
                                        <span *ngIf="row.scara !== null">,
                                            <strong>Sc.</strong>
                                            {{ row.scara }}</span>
                                        <span *ngIf="row.etaj !== null">,
                                            <strong>Et.</strong>
                                            {{ row.etaj }}</span>
                                        <span *ngIf="row.apartament !== null">,
                                            <strong>Ap.</strong>
                                            {{ row.apartament }}</span>
                                    </p>
                                    <p class="m-0">
                                        <strong>Observații:</strong>
                                        {{ row.observatii || "n/a" }}
                                    </p>
                                </div>
                                <div class="col-sm-4 responsive-card-btns">
                                    <button type="button" class="mb-2 mr-2 btn-transition btn btn-lg btn btn-outline-primary float-right btn-special" (click)="updateFormAddress(row)">
                                        <img class="btn-img" src="assets/icons/edit.svg" alt="" /> MODIFICAȚI
                                    </button>
                                    <button type="button" class="mb-2 mr-2 btn-transition btn btn-lg btn btn-outline-danger float-right btn-special" (click)="deleteAddress(row.id)">
                                        <img class="btn-img" src="assets/icons/delete.svg" alt="" /> ȘTERGEȚI
                                    </button>
                                </div>
                            </div>
                        </mat-card>

                        <div class="smallHeader mt-5 mb-3" *ngIf="userType == 2">
                            <h5 class="border-bottom">
                                <strong>Adresă Sediu Social</strong>
                            </h5>
                        </div>
                        <div *ngIf="userType == 2">
                            <mat-card *ngFor="let row of listAddresses.sediu" class="justify-content-between mb-1">
                                <div class="row m-0 w-100">
                                    <div class="col-sm-8">
                                        <p class="m-0">
                                            <strong>Județ:</strong> {{ row.judet_name }},
                                            <strong>Oraș:</strong> {{ row.oras }},
                                            <strong>Cod poștal:</strong> {{ row.cod_postal || "n/a" }},
                                            <strong>Sector:</strong> {{ row.sector || "n/a" }}
                                        </p>
                                        <p class="m-0">
                                            <strong>Strada:</strong> {{ row.strada }},
                                            <strong>Număr:</strong> {{ row.numar }}
                                            <span *ngIf="row.bloc !== null">,
                                                <strong>Bl.</strong> {{ row.bloc }}</span>
                                            <span *ngIf="row.scara !== null">,
                                                <strong>Sc.</strong> {{ row.scara }}</span>
                                            <span *ngIf="row.etaj !== null">,
                                                <strong>Et.</strong> {{ row.etaj }}</span>
                                            <span *ngIf="row.apartament !== null">,
                                                <strong>Ap.</strong>
                                                {{ row.apartament }}</span>
                                        </p>
                                        <p class="m-0">
                                            <strong>Observații:</strong>
                                            {{ row.observatii || "n/a" }}
                                        </p>
                                    </div>
                                    <div class="col-sm-4">
                                        <button type="button" class="mb-2 mr-2 btn-transition btn btn-lg btn btn-outline-primary float-right btn-special" (click)="updateFormAddress(row)">
                                            <img class="btn-img" src="assets/icons/edit.svg" alt="" /> MODIFICAȚI
                                        </button>
                                        <button type="button" class="mb-2 mr-2 btn-transition btn btn-lg btn btn-outline-danger float-right btn-special" (click)="deleteAddress(row.id)">
                                            <img class="btn-img" src="assets/icons/delete.svg" alt="" /> ȘTERGEȚI
                                        </button>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>

                    <form class="settings-container__left-address-form" *ngIf="updateFormShowAddress" [formGroup]="formAddress" (ngSubmit)="addAddress()">
                        <mat-form-field *ngIf="userType == 2" appearance="fill">
                            <mat-label>Tip Adresă</mat-label>
                            <mat-select matSelect formControlName="type" required>
                                <mat-option value="-1">-- Alegeți --</mat-option>
                                <mat-option value="1">Domiciliu</mat-option>
                                <mat-option value="2">Sediu Social</mat-option>
                            </mat-select>
                            <mat-hint>Alegeți tipul adresei</mat-hint>
                            <mat-error *ngIf="formAddress.controls['type']?.hasError('notDefault')">
                                Câmp obligatoriu
                            </mat-error>
                        </mat-form-field>

                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Județ</mat-label>
                                    <mat-select matSelect formControlName="judet" required="required">
                                        <mat-option value="-1">-- Alegeți --</mat-option>
                                        <mat-option *ngFor="let judet of judete" [value]="judet.id">
                                            {{ judet.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint>Alegeți județul</mat-hint>
                                    <mat-error *ngIf="formAddress.controls['judet']?.hasError('notDefault')">
                                        Câmp obligatoriu
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Oraș</mat-label>
                                    <input formControlName="oras" matInput type="text" required="required" />
                                    <mat-hint>Introduceți orașul</mat-hint>
                                    <mat-error *ngIf="oras && (oras.dirty || oras.touched) && oras.hasError('required')">
                                        <strong>Câmp obligatoriu</strong>
                                    </mat-error>
                                    <mat-error *ngIf="oras && (oras.dirty || oras.touched) && oras.hasError('minlength')">
                                        <strong>Minim 2 caractere</strong>
                                    </mat-error>
                                    <mat-error *ngIf="oras && (oras.dirty || oras.touched) && oras.hasError('maxlength')">
                                        <strong>Max {{env.config.validatorsAccrossApp.maxStringLength}} caractere</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <!-- cod postal -->
                                <mat-form-field appearance="fill">
                                    <mat-label>Cod poștal</mat-label>
                                    <input formControlName="cod_postal" matInput type="text" />
                                    <mat-hint>Introduceți codul poștal</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Sector</mat-label>
                                    <mat-select matSelect formControlName="sector">
                                        <mat-option [value]='null'>-- Alegeți --</mat-option>
                                        <mat-option value="1">Sector 1</mat-option>
                                        <mat-option value="2">Sector 2</mat-option>
                                        <mat-option value="3">Sector 3</mat-option>
                                        <mat-option value="4">Sector 4</mat-option>
                                        <mat-option value="5">Sector 5</mat-option>
                                        <mat-option value="6">Sector 6</mat-option>
                                    </mat-select>
                                    <mat-hint>Alegeți sectorul</mat-hint>
                                    <mat-error *ngIf="formAddress.controls['sector']?.hasError('required')">
                                        Câmp obligatoriu
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-9">
                                <mat-form-field appearance="fill">
                                    <mat-label>Strada</mat-label>
                                    <input formControlName="strada" matInput type="text" required="required" />
                                    <mat-hint>Introduceți strada</mat-hint>
                                    <mat-error *ngIf="strada && (strada.dirty || strada.touched) && strada.hasError('required')">
                                        <strong>Câmp obligatoriu</strong>
                                    </mat-error>
                                    <mat-error *ngIf="strada && (strada.dirty || strada.touched) && strada.hasError('minlength')">
                                        <strong>Minim 2 caractere</strong>
                                    </mat-error>
                                    <mat-error *ngIf="strada && (strada.dirty || strada.touched) && strada.hasError('maxlength')">
                                        <strong>Max {{env.config.validatorsAccrossApp.maxStringLength}} caractere</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Număr</mat-label>
                                    <input formControlName="numar" matInput type="text" required="required" />
                                    <mat-hint>Introduceți numărul</mat-hint>
                                    <mat-error *ngIf="numar && (numar.dirty || numar.touched) && numar.hasError('required')">
                                        <strong>Câmp obligatoriu</strong>
                                    </mat-error>                                    
                                </mat-form-field>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <input formControlName="bloc" matInput placeholder="Bloc" type="text" />
                                    <mat-hint>Introduceți blocul</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <input formControlName="scara" matInput placeholder="Scara" type="text" />
                                    <mat-hint>Introduceți scara</mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <input formControlName="etaj" matInput placeholder="Etaj" type="text" />
                                    <mat-hint>Introduceți etajul</mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <input formControlName="apartament" matInput placeholder="Apartament" type="text" />
                                    <mat-hint>Introduceți apartamentul</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <textarea formControlName="observatii" matInput placeholder="Observatii"></textarea>
                                <mat-hint>Introduceți observații</mat-hint>
                            </mat-form-field>
                        </div>


                        <!-- buttons -->
                        <div class="action-container responsive-action-container">
                            <button type="button" (click)="toggleFormAddress()">
                                ÎNCHIDE
                            </button>

                            <button type="submit" *ngIf="!isAddressUpdate">
                                ADAUGĂ
                            </button>

                            <button type="button" (click)="updateAddress()" *ngIf="isAddressUpdate">
                                ACTUALIZARE INFORMAȚII
                            </button>
                        </div>
                    </form>

                </div>
            </mat-card>
        </div>
    </div>

</div>