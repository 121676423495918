import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CarieraService } from './cariera.service';
import { helper } from 'src/environments/helper';
import { finalize } from 'rxjs';
import { AppFilesService } from 'src/app/services/app-files.service';

@Component({
    selector: 'app-cariera',
    templateUrl: './cariera.component.html',
    styleUrls: ['./cariera.component.scss']
})
export class CarieraComponent implements OnInit {
    public portalLive = environment.portalLive;
    institution!: string;
    documente!: any[];

    isLoading = false;
    helper = helper;

    constructor(
        private route: ActivatedRoute,
        private carieraService: CarieraService,
        private appFilesService: AppFilesService
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.institution = params.get('institution')!;
            this.getDocumenteForInstitution(this.institution);
        });
    }

    getDocumenteForInstitution(institution: string) {
        this.isLoading = true;
        this.carieraService.getDocumente(institution)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (result: any) => {
                    this.documente = result.data;
                    console.log('Documente', this.documente);
                },
                error: (error) => {
                    console.error('Error fetching documente', error);
                }
            });
    }

    getAllDocuments(documents: any): any[] {
        if (!documents) return [];
        return Object.values(documents).flat();
    }

    downloadFile(id: number) {
        let fullPath = this.carieraService.fileDownloadUrl + id;
        this.isLoading = true;
        this.appFilesService.downloadFile(fullPath)
            .then(() => {
                this.isLoading = false;
            })
            .catch(err => {
                console.error('File download error: ', err);
                this.isLoading = false;
            })
    }
}
