import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-termeni-conditii-modal',
    templateUrl: './termeni-conditii-modal.component.html',
    styleUrls: ['./termeni-conditii-modal.component.scss']
})
export class TermeniConditiiModalComponent implements OnInit {
    contentDetails: any;
    isLoading = false;
    slug: any = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<TermeniConditiiModalComponent>,
        private authService: AuthService,
        private notificationService: NotificationService,
    ) {
        this.slug = data.slug;
    }

    ngOnInit(): void {
        this.getContent();
    }

    closeDialog(data: any = null) {
        this.dialogRef.close({
            data: data
        });
    }

    getContent() {
        this.isLoading = true;
        this.authService.getContent(this.slug)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: async (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error);
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.isLoading = false;
                            this.contentDetails = response.data;
                            return;
                        }
                    } else {
                        this.isLoading = false;
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        await this.notificationService.warningSwal(environment.config.customNotifications.headers.error, errorMessage, environment.config.customNotifications.icons.error);
                    }
                },
                error: (res: any) => {
                    this.isLoading = false;
                    this.notificationService.handleHttpError(res.error);
                }
            })
    }
}
