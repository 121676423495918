<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="declaratii-container">
    <div class="declaratii-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="declaratii-welcome-card-content d-flex flex-column gap-4 justify-content-start align-self-stretch">
            <span class="institution">
                {{institution === 'dgaspc' ? 'Direcția Generală de Asistență Socială și Protecția Copilului' : ''}}
            </span>
            <span class="subtitle">Declarații<span *ngIf="selectedYear"> {{selectedYear}}</span></span>
        </div>
    </div>

    <div class="declaratii-content d-flex flex-column align-items-start align-self-stretch">
        <div class="declaratii-content-container d-flex flex-column align-self-stretch">
            <p *ngIf="!selectedYear" class="header-text text-center">Selectați anul pentru a vizualiza declarațiile</p>
            <div class="row">
                <div class="col-sm-12" *ngIf="!selectedYear; else dataView">
                    <ul class="year-list">
                        <li *ngFor="let year of years">
                            <p class="pointer" (click)="selectYear(year)">
                                <span>{{year}}</span>
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </p>
                        </li>
                    </ul>
                </div>
                <ng-template #dataView>
                    <div class="p-2">
                        <button (click)="goBack()" class="navigation__btn" mat-stroked-button color="primary">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                            ÎNAPOI
                        </button>
                    </div>
                    <div class="col-sm-12" *ngFor="let item of documente">
                        <div class="declaratii-list d-flex justify-content-between align-items-center">
                            <span>{{item.name}}</span>
                            <div class="d-flex justify-content-between align-items-center gap-3">
                                <button *ngIf="item.declaratie_avere?.length" type="button"
                                    (click)="downloadFile(item.declaratie_avere[0].id)" class="download-btn">
                                    <i class="fa-solid fa-cloud-arrow-down"></i>
                                    Declarație de avere
                                </button>

                                <button *ngIf="item.declaratie_interese?.length" type="button"
                                    (click)="downloadFile(item.declaratie_interese[0].id)" class="download-btn">
                                    <i class="fa-solid fa-cloud-arrow-down"></i>
                                    Declarație de interese
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>

<div class="section-spinner position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
    *ngIf="isLoading">
    <div class="spinner">
        <mat-spinner></mat-spinner>
        <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
    </div>
</div>