import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeclaratiiService } from './declaratii.service';
import { helper } from 'src/environments/helper';
import { finalize } from 'rxjs';
import { AppFilesService } from 'src/app/services/app-files.service';

@Component({
    selector: 'app-declaratii',
    templateUrl: './declaratii.component.html',
    styleUrls: ['./declaratii.component.scss']
})
export class DeclaratiiComponent implements OnInit {
    public portalLive = environment.portalLive;
    institution!: string;
    selectedYear: number | null = null;
    years: number[] = [];
    documente!: any[];

    isLoading = false;
    helper = helper;

    constructor(
        private route: ActivatedRoute,
        private declaratiiService: DeclaratiiService,
        private appFilesService: AppFilesService
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.institution = params.get('institution')!;
            this.getYearsForInstitution(this.institution);
        });
    }

    getYearsForInstitution(institution: string) {
        this.isLoading = true;
        this.declaratiiService.getYears(institution)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (result: any) => {
                    this.years = result.data;
                },
                error: (error) => {
                    console.error('Error fetching years', error);
                }
            });
    }

    selectYear(year: number) {
        this.selectedYear = year;
        this.isLoading = true;

        this.declaratiiService.getDeclaratii(this.institution, year)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (result: any) => {
                    this.documente = result.data;
                },
                error: (error) => {
                    console.error('Error fetching documents', error);
                }
            });
    }

    // Reset to the years view
    goBack() {
        this.selectedYear = null;
        this.documente = [];
        this.getYearsForInstitution(this.institution);
    }

    downloadFile(id: number) {
        let fullPath = this.declaratiiService.fileDownloadUrl + id;
        this.isLoading = true;
        this.appFilesService.downloadFile(fullPath)
            .then(() => {
                this.isLoading = false;
            })
            .catch(err => {
                console.error('File download error: ', err);
                this.isLoading = false;
            })
    }
}
