import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, switchAll } from 'rxjs';
import { Solicitare } from 'src/app/interfaces/request.interface';
import { helper } from 'src/environments/helper';
import { SolicitariService } from './solicitari.service';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DataTablesResponse } from './data-table-response.interface';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { ToastService } from 'src/app/services/toast.service';
import { SolicitareType } from './models';
import { RequestPageService } from './urbanism/request-page.service';
import { ConfigExtensions } from 'src/app/utils/config-extensions';
@Component({
    selector: 'app-solicitari',
    templateUrl: './solicitari.component.html',
    styleUrls: ['./solicitari.component.scss'],
})
export class SolicitariComponent implements OnInit, AfterViewInit {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;

    isLoading = false;
    showCard = false;
    statusValue: any = '-1';
    draftValue: any = '-1';
    institutieValue: any = '-1';
    solicitareValue!: any;
    helper = helper;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    status: { key: string; value: any }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'În așteptare preluare', value: 0 },
        { key: 'În lucru', value: 1 },
        { key: ' Soluționată', value: 2 },
        { key: 'Clasată', value: 3 },
    ];

    draft: { key: string; value: any }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Doar solicitari', value: 0 },
        { key: 'Doar draft', value: 1 },
    ];

    institutie: { key: string; value: any }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Suport', value: 0 },
        { key: 'Urbanism', value: 1 },
        //{ key: 'DGITL', value: 2 }
    ];

    resetForm() {
        this.statusValue = '-1';
        this.solicitareValue = null;

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        // console.log(this.statusValue, this.solicitareValue);

        this.rerenderDT(true);
    }

    toggleCard() {
        this.showCard = !this.showCard;
    }
    requestId!: number;

    dtLink =
        environment.interop.authCore.basePath +
        environment.interop.authCore.solicitare.listDT;

    pageSlug: string = 'REQUEST_PAGE';
    // pageSlug: string = 'section_1'; // To test local/development

    constructor(
        private router: Router,
        private store: SolicitariService,
        private dialogService: DialogService,
        private http: HttpClient,
        private dialog: MatDialog,
        private toastService: ToastService,
        private requestPageService: RequestPageService
    ) { }

    ngOnInit() {
        this.renderDT();
        // console.log(this.statusValue, this.solicitareValue);
    }
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            serverSide: true,
            searching: false,
            responsive: true,
            ajax: (dataTablesParameters: any, callback: any) => {
                dataTablesParameters.filters = {
                    solicitare_numar: this.solicitareValue,
                    rank: this.statusValue,
                    draft: this.draftValue,
                    tipSolicitare: this.institutieValue,
                };
                // console.log(dataTablesParameters);

                this.isLoading = true;
                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            order: [[0, 'desc']], // pentru ordonare dupa id DESC - ultimele records sa apara primele
            columns: [
                {
                    title: 'Id',
                    data: 'id',
                    visible: false,
                },
                {
                    title: 'Instituție',
                    data: 'type',
                    render: (data: any, type: any, row: any) => {
                        return `${row.type_name}`;
                    },
                },
                {
                    title: 'Solicitare Număr',
                    data: 'solicitare_numar',
                    render: (data: any, type: any, row: any) => {
                        if (row.solicitare_numar == null || row.solicitare_numar == 0) {
                            return `<span>n/a</span>`;
                        }
                        return `<span>${row.solicitare_numar}</span>`;
                    },
                },
                {
                    title: 'Denumire',
                    data: 'subject',
                },
                {
                    title: 'Dată Solicitare',
                    data: 'solicitare_data',
                },

                {
                    title: 'Stare Solicitare',
                    data: 'rank_name',
                    render: (data: any, type: any, row: any) => {
                        const bgColor = row.rank_color || '';
                        return `<span style="background-color: ${bgColor}; color: white; border-radius: 4px; padding: 3px 10px">${data}</span>`;
                    },
                },
                {
                    data: 'draft',
                    visible: false,
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    orderable: false,
                    render: (data: any, type: any, row: any, met: any) => {
                        return `
						<img style="width:20px;margin-right:10px" src="assets/icons/arrow.jpg"></img>
						
						`;
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const self = this;
                $('td', row).off('click');

                $('td:not(:first-child)', row).on('click', () => {
                    this.navigateToRequest(data);
                });
                return row;
            },

        };
    }

    rerenderDT(paginate: boolean = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
        this.showCard = false;
    }

    AddSolicitari() { }

    navigateToRequest(data: any) {
        if (data.draft) {
            this.requestPageService.setDraft({ id: data.id });
            var navUrl = this.requestPageService.SOLICITARI_URBANISM_URL + data.slug;
            this.router.navigate([navUrl]);
            return;
        }

        switch (data.type) {
            case SolicitareType.Suport:
                this.router.navigate([
                    '/dashboard/solicitari/view-solicitare',
                    data.type_name,
                    data.extern_id,
                ]);
                break;
            case SolicitareType.DGITL:
                this.router.navigate([
                    '/dashboard/solicitari/view-solicitare',
                    data.type_name,
                    data.extern_id,
                ]);
                break;
            case SolicitareType.AutorizariComerciale:
                this.router.navigate([
                    '/dashboard/solicitari/view-solicitare',
                    'AutorizariComerciale',
                    data.extern_id,
                ]);
                break;

            case SolicitareType.Programari:
                this.router.navigate([
                    '/dashboard/solicitari/view-solicitare',
                    'Programari',
                    data.extern_id,
                ]);
                break;

            case SolicitareType.Urbanism:
                this.router.navigate([
                    '/dashboard/solicitari/institutie/urbanism/view-solicitare',
                    data.extern_id,
                ]);
                break;
            case SolicitareType.DGASPC:
                this.router.navigate([
                    '/dashboard/solicitari/institutie/asistenta-sociala/view-solicitare',
                    data.extern_id,
                ]);
                break;
            default:
                this.toastService.openToast({
                    title: environment.config.customNotifications.headers.error,
                    message: `Vizualizarea cererilor de tipul ${data.type_name} este in lucru`,
                    type: environment.config.customNotifications.icons.warning,
                });
                break;
        }
    }

    isProduction() {
        return ConfigExtensions.isProductionWithLimitedFeatures();
    }

    initializeRequest(): void {
        if (this.isProduction()) {
            this.router.navigate(['/dashboard/solicitari/institutie/urbanism']);
        } else {
            this.router.navigate(['/dashboard/solicitari/institutie']);
        }
    }
}
