<div class="dialog" *ngIf="!isLoading">
    <div *ngFor="let row of contentDetails" class="dialog__container">
        <div class="dialog__container-header d-flex justify-content-between align-items-center">
            <p>{{ row.title }}</p>
            <div>
                <button type="button" class="close d-flex align-items-center" aria-label="Close"
                    (click)="closeDialog()">
                    <span class="close-icon" aria-hidden="true">&times;</span>
                    <span class="close-text">&nbsp;&nbsp;ÎNCHIDE</span>
                </button>
            </div>
        </div>

        <span [innerHTML]="row.content"></span>
    </div>
</div>
<div class="spinner-overlay d-flex h-100 align-items-center justify-content-center" *ngIf="isLoading">
    <mat-spinner color="primary"></mat-spinner>
</div>