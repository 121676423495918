import { Injectable, Inject } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SolicitareType, UploadDocument } from '../models';
import { Router } from '@angular/router';
import { finalize, map } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { DraftDataDto } from '../../../models';
import { SOLICITARI_URBANISM_LIST } from './common/constants';

@Injectable({
    providedIn: 'root',
})
export class RequestPageService {
    public SOLICITARI_URBANISM_URL: string =
        '/dashboard/solicitari/institutie/urbanism/';
    numeSolicitare: string = '';
    blockFormSubmit: boolean = false;
    urbBasePath = environment.interop.urbanismCore.basePath;
    formHomeAddress!: FormGroup;
    formOfficeAddress!: FormGroup;
    formBeneficiar!: FormGroup;
    formSign!: FormGroup;
    documentName!: string;
    isLoading = false;
    env: any = environment;

    private draftData: DraftDataDto = { id: null };

    private token!: string;

    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    errorType: string = environment.config.customNotifications.icons.error;
    // general success alert
    successTitle: string =
        environment.config.customNotifications.headers.successForm;
    successIcon: string = environment.config.customNotifications.icons.success;
    successType: string = environment.config.customNotifications.icons.success;

    private formCerere: null | FormGroup<any> = null;
    private taxAmount!: number | null;

    private storeFormUrl =
        this.urbBasePath + environment.interop.urbanismCore.api.cerere.store;
    private printFormUrl =
        this.urbBasePath + environment.interop.urbanismCore.api.cerere.printCerere;

    private fileUploadUrl =
        this.urbBasePath + environment.interop.urbanismCore.api.cerere.fileUpload;

    private saveMessageSolicitareUrl =
        this.urbBasePath +
        environment.interop.urbanismCore.api.cerere.saveMessageSolicitare;
    private viewSolicitareURL =
        this.urbBasePath +
        environment.interop.urbanismCore.api.cerere.viewSolicitare;

    private findDraftUrl =
        environment.interop.authCore.basePath +
        environment.interop.authCore.solicitare.findDraft;
    private storeDraftUrl =
        this.urbBasePath + environment.interop.urbanismCore.api.cerere.storeDraft;
    private httpOptions: any;

    constructor(
        private router: Router,
        private http: HttpClient,
        private authService: AuthService,
        private notificationService: NotificationService,
        private fb: FormBuilder,
        private toastService: ToastService,
        private userService: UserService
    ) { }

    isProduction() {
        return this.env.production;
    }

    isRequestFormLoaded(): boolean {
        return this.formCerere !== null && this.formCerere.controls !== null;
    }

    setDocumentName(documentName: string): void {
        this.documentName = documentName;
    }

    setDocumentNameBySlug(requestSlug: string): void {
        for (const category of SOLICITARI_URBANISM_LIST) {
            for (const subitem of category.subitems) {
                if (subitem.slug === requestSlug) {
                    this.documentName = subitem.name;
                    return;
                }
            }
        }

        return;
    }

    setRequestName(numeSolicitare: string) {
        this.numeSolicitare = numeSolicitare;
        this.setDocumentNameBySlug(numeSolicitare);
    }

    setBlockFormSubmit(blockFormSubmit: boolean) {
        this.blockFormSubmit = blockFormSubmit
    }

    getBlockFormSubmit() {
        return this.blockFormSubmit
    }

    getNumeSolicitare() {
        return this.numeSolicitare;
    }

    saveMessageSolicitare(solicitareId: number, data: any) {
        return this.http.post<{ data: any }>(
            this.saveMessageSolicitareUrl + solicitareId,
            data
        );
    }

    viewSolicitare(solicitareId: number) {
        return this.http.get<{ data: any }>(this.viewSolicitareURL + solicitareId);
    }

    setForm(formGroups: { [key: string]: FormGroup }): void {
        this.formHomeAddress = this.fb.group({
            homeAddress: ['', [Validators.required]],
        });

        this.formOfficeAddress = this.fb.group({
            officeAddress: ['', []],
        });

        if (this.userService.isUserPersoanaJuridica()) {
            const officeAddressControl = this.formOfficeAddress.get('officeAddress');
            officeAddressControl?.setValidators([Validators.required]);
        }

        this.formSign = this.fb.group({
            signature: [null, [Validators.required]],
            signatureBase64: ['', [Validators.required]],
        });

        this.formBeneficiar = this.fb.group({
            beneficiar: ['', []],
        });

        const defaultFormGroups = {
            formHomeAddress: this.formHomeAddress,
            formOfficeAddress: this.formOfficeAddress,
            formSign: this.formSign,
            formBeneficiar: this.formBeneficiar,

        };

        const mergedFormGroups = { ...defaultFormGroups, ...formGroups };
        this.formCerere = this.fb.group(mergedFormGroups);
    }

    getForm(): null | FormGroup<any> {
        return this.formCerere;
    }

    getDocumentName() {
        return this.documentName;
    }

    clearForm() {
        this.formCerere = null;
    }

    clearDraftData() {
        this.draftData = { id: null };
    }

    sign(signature: any) {
        this.formSign.patchValue(signature);
    }

    setTaxAmount(amount: number | null): void {
        this.taxAmount = amount;
    }

    getTaxAmount(): number | null {
        return this.taxAmount;
    }

    getFormByName(formName: string) {
        return this.formCerere?.get(formName) as FormGroup;
    }

    getControlValue(formName: string, controlName: string) {
        const form = this.formCerere?.get(formName) as FormGroup;
        return form.controls[controlName].value;
    }

    getFormArray(formName: string, formArrName: string) {
        const form = this.formCerere?.get(formName) as FormGroup;
        return form.controls[formArrName] as FormArray;
    }

    deleteUploadedFile(key: string, fileName: string, formName = 'formUpload') {
        const fa = this.getFormArray(formName, key);

        const indexToDelete = fa.controls.findIndex((control) => {
            const filenameControl = control.get('filename') as FormControl;
            return filenameControl.value === fileName;
        });

        if (indexToDelete >= 0) {
            const fileInput = document.getElementById(key) as HTMLInputElement;

            if (fileInput) {
                // Get the current list of files in the input element
                const currentFiles = Array.from(fileInput.files || []);

                // Remove the deleted file from the list
                const updatedFiles = currentFiles.filter(file => {
                    // Extract the unique 'filename' from the metadata
                    const fileMetadata = this.getFileMetadata(file, fa);
                    return fileMetadata.filename !== fileName;
                });

                // Create a new DataTransfer object (this is a trick to reset the FileList)
                const dataTransfer = new DataTransfer();

                // Add the remaining files back to the DataTransfer object
                updatedFiles.forEach(file => dataTransfer.items.add(file));

                // Update the input with the new FileList
                fileInput.files = dataTransfer.files;
            }

            fa.removeAt(indexToDelete);
        }
    }

    getFileMetadata(file: File, fa: FormArray): { filename: string, original_name: string, ext: string } {
        for (const control of fa.controls) {
            const fileGroup = control as FormGroup;
            const fileMetadata = {
                filename: fileGroup.get('filename')?.value,
                original_name: fileGroup.get('original_name')?.value,
                ext: fileGroup.get('ext')?.value,
            };

            if (file.name === fileMetadata.original_name) {
                return fileMetadata;
            }
        }
        return { filename: '', original_name: '', ext: '' };
    }

    upload(
        file: File,
        key: string,
        documents = new Array<UploadDocument>(),
        formName = 'formUpload'
    ) {
        this.setUploading(documents, key, true);
        const formData = new FormData();
        formData.append('file', file);
        // const uploadApi = this.authService.fileUpload(formData);
        const uploadApi = this.fileUpload(formData);

        const subscription = uploadApi.subscribe({
            next: (response: any) => {
                this.toastService.openToast({
                    title: this.successTitle,
                    message: 'Fișierul dvs. a fost adăugat cu succes.',
                    type: this.successIcon,
                });

                const fileGroup = this.fb.group({
                    filename: [response.data.filename],
                    original_name: [response.data.original_name],
                    ext: [response.data.ext],
                });

                const upload = this.getFormArray(formName, key);
                upload.push(fileGroup);
            },
            error: (error: any) => {
                this.setUploading(documents, key, false);
                console.error(`Error uploading file ${file.name}:`, error);
                this.notificationService.handleHttpError(error.error);
            },
            complete: () => {
                subscription.unsubscribe();
                this.setUploading(documents, key, false);
            },
        });
    }

    fileUpload(formData: FormData) {
        return this.http.post<{ data: any }>(this.fileUploadUrl, formData);
    }

    getDocumentByKey(documents: UploadDocument[], desiredKey: string) {
        return documents.find((document) => document.key === desiredKey);
    }

    getUploading(documents: UploadDocument[], desiredKey: string) {
        const desiredDocument = this.getDocumentByKey(documents, desiredKey);
        if (desiredDocument) {
            return desiredDocument.uploading;
        } else {
            console.log('Document not found.');
        }
        return false;
    }

    setUploading(
        documents: UploadDocument[],
        desiredKey: string,
        value: boolean
    ) {
        const desiredDocument = this.getDocumentByKey(documents, desiredKey);
        if (desiredDocument) {
            desiredDocument.uploading = value;
        } else {
            console.log('Document not found.');
        }
    }

    addFormGroup(controlName: string, newFormGroup: FormGroup): void {
        if (this.isRequestFormLoaded()) {
            // Add the new FormGroup to the parent formCerere with the given control name
            this.formCerere!.addControl(controlName, newFormGroup);
        } else {
            console.error('The request form is not loaded, cannot add a form group.');
        }
    }

    isFormValid(): boolean {
        return this.formCerere !== null && this.formCerere.valid;
    }

    areFormsValid(forms: FormGroup[]): boolean {
        return forms.every((formGroup) => {
            return formGroup && formGroup.valid;
        });
    }

    checkFormsValidityAndMarkTouched(forms: FormGroup[]) {
        forms.forEach((formGroup) => {
            formGroup.markAllAsTouched();
            formGroup.updateValueAndValidity();
        });
    }

    checkAllFormsValidityAndMarkTouched() {
        if (this.formCerere) {
            this.formCerere.markAllAsTouched();
            this.formCerere.updateValueAndValidity();
            return;
        }
    }

    checkForError(
        formGroupName: string,
        formControlName: string,
        errorName: string = ''
    ) {
        const formGroup = this.getFormByName(formGroupName);

        if (errorName) {
            return (
                formGroup.hasError(errorName, formControlName) &&
                formGroup.controls[formControlName].touched
            );
        }

        return (
            formGroup.controls[formControlName].valid &&
            formGroup.controls[formControlName].touched
        );
    }

    navigateToNext(nextUrlSegment: string, formsToCheck: FormGroup[]) {
        this.checkFormsValidityAndMarkTouched(formsToCheck);

        if (!this.areFormsValid(formsToCheck)) {
            this.notificationService
                .warningSwal(
                    environment.config.customNotifications.headers.error,
                    'Cererea dumneavoastră are erori. Verificați din nou câmpurile obligatorii şi validitatea informațiilor.',
                    environment.config.customNotifications.icons.warning
                )
                .then((result) => {
                    if (result.isConfirmed) {
                        setTimeout(() => {
                            console.log('scrolling to top ...');
                            window.scrollTo({ top: 0, behavior: 'auto' });
                        }, 1000);
                    }
                });

            return;
        }

        this.router.navigate([
            this.SOLICITARI_URBANISM_URL,
            this.getNumeSolicitare(),
            nextUrlSegment,
        ]);
    }

    sendRequest() {
        this.checkAllFormsValidityAndMarkTouched();

        this.isLoading = true;
        if (!this.isFormValid()) {
            this.notificationService
                .warningSwal(
                    environment.config.customNotifications.headers.error,
                    'Cererea dumneavoastră nu poate fi trimisă deoarece există erori. Verificați din nou câmpurile obligatorii și validitatea informațiilor.',
                    environment.config.customNotifications.icons.error
                )
                .then((result) => {
                    if (result.isConfirmed) {
                        setTimeout(() => {
                            console.log('scrolling to top ...');
                            window.scrollTo({ top: 0, behavior: 'auto' });
                        }, 1000);
                    }
                });

            return;
        }

        // stergere draft
        localStorage.removeItem(this.getNumeSolicitare());

        //prepare data to send
        const dataToSend = this.getDataToSend();
        // send the request
        return this.http.post(this.storeFormUrl, dataToSend).subscribe({
            next: (res: any) => {
                let response = typeof res.status_code !== 'undefined' ? res : res.error;
                if (typeof response.status_code !== 'undefined') {
                    if (
                        response.status_code == 200 &&
                        typeof response.data !== 'undefined'
                    ) {
                        this.isLoading = false;
                        let message = '';
                        if (response.data.reg_number !== 'n/a') {
                            message =
                                'Solicitarea dvs. cu numărul ' +
                                response.data.reg_number +
                                '/' +
                                response.data.reg_date +
                                ' a fost trimisă cu succes!';
                        } else {
                            message = 'Solicitarea dvs a fost trimisă cu succes!';
                        }
                        this.notificationService.warningSwal(
                            this.successTitle,
                            message,
                            this.successIcon
                        );

                        this.clearDraftData();

                        this.router.navigate(['/dashboard/solicitari/institutie/urbanism']);
                    }
                } else {
                    // add sentry
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        errorMessage,
                        this.errorIcon
                    );
                    this.isLoading = false;
                }
            },
            error: (err: any) => {
                this.isLoading = false;
                this.notificationService.handleHttpError(err);
            },
        });
    }

    getDataToSend() {
        return {
            //...this.formCerere ?.value,
            ...this.formCerere?.getRawValue(),
            slug: this.getNumeSolicitare(),
            type: SolicitareType.Urbanism,
        };
    }

    saveDraft() {
        this.notificationService
            .warningSwal(
                'Salvare draft cerere pentru mai târziu',
                'Cererea dumneavoastră va fi salvată fără fișierele încărcate (dacă există).',
                environment.config.customNotifications.icons.warning,
                'Confirm',
                'Anulare',
                true
            )
            .then((result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    let formValueString = '';
                    const data = this.formCerere?.value;
                    // Check if 'formUpload' exists before removing it
                    if ('formUpload' in data) {
                        // 'formUpload' exist
                        const formCerereWithoutUpload = { ...data };
                        delete formCerereWithoutUpload.formUpload;

                        formValueString = JSON.stringify(formCerereWithoutUpload);
                        console.log(
                            'saving draft form data, without formUpload: ',
                            formCerereWithoutUpload
                        );
                    } else {
                        // 'formUpload' doesn't exist
                        formValueString = JSON.stringify(data);
                        console.log('saving draft form data: ', data);
                    }

                    this.http
                        .post(this.storeDraftUrl, {
                            draft_id: this.draftData.id,
                            draft_slug: this.getNumeSolicitare(),
                            draft_json: formValueString,
                        })
                        .pipe(finalize(() => (this.isLoading = false)))
                        .subscribe({
                            next: (res: any) => {
                                console.log(res);
                                // this.setDraft({ id: res.data.data.id }); // strange looking response with data.data :)

                                this.toastService.openToast({
                                    title: this.successTitle,
                                    message: 'Draft-ul a fost salvat cu succes.',
                                    type: this.successIcon,
                                });
                            },
                            error: (err: any) => {
                                this.notificationService.handleHttpError(err);
                            },
                        });
                }
            });
        /*
                // DEPRECATED
                //local storage version
                this.notificationService
                    .warningSwal(
                        'Salvare draft cerere pentru mai târziu',
                        'Cererea dumneavoastră va fi salvată fără fișierele încărcate (dacă există).',
                        environment.config.customNotifications.icons.warning,
                        'Confirm',
                        'Anulare',
                        true
                    )
                    .then((result) => {
                        if (result.isConfirmed) {
                            let formValueString = '';
                            const data = this.formCerere ?.value;
                            // Check if 'formUpload' exists before removing it
                            if ('formUpload' in data) {
                                // 'formUpload' exist
                                const formCerereWithoutUpload = { ...data };
                                delete formCerereWithoutUpload.formUpload;
        
                                formValueString = JSON.stringify(formCerereWithoutUpload);
                                console.log(
                                    'saving draft form data, without formUpload: ',
                                    formCerereWithoutUpload
                                );
                            } else {
                                // 'formUpload' doesn't exist
                                formValueString = JSON.stringify(data);
                                console.log('saving draft form data: ', data);
                            }
        
                            localStorage.setItem(this.getNumeSolicitare(), formValueString);
                        }
                    });
                    */
    }

    /* init draft from localStorage - replaced with initDraft which loads the data from server
        // DEPRICATED
        initFromDraft() {
            const storedFormValueString = localStorage.getItem(
                this.getNumeSolicitare()
            );
    
            if (storedFormValueString) {
                return this.notificationService
                    .warningSwal(
                        'Încărcare date din draft',
                        `Pentru acest tip de cerere aveți salvat un draft. Doriți încărcarea datelor din draft?`,
                        environment.config.customNotifications.icons.info,
                        'Încarcă draft',
                        'Omite încărcare draft',
                        true
                    )
                    .then((result) => {
                        if (result.isConfirmed) {
                            const storedFormValue = JSON.parse(storedFormValueString);
                            this.formCerere ?.patchValue(storedFormValue);
                        }
                    });
            }
    
            // If there is no stored value, resolve with an empty value
            return Promise.resolve();
        }
        */

    setDraft(data: DraftDataDto): void {
        this.draftData = data;
    }

    getDraft(): DraftDataDto {
        return this.draftData;
    }

    initDraft(draftData: DraftDataDto) {
        console.log('init draft started...');
        const subscription = this.http
            .get<{ data: any }>(this.findDraftUrl + draftData.id)
            .subscribe({
                next: (response: any) => {
                    const storedFormValue = JSON.parse(response.data.solicitare_json);
                    this.formCerere?.patchValue(storedFormValue);

                    this.toastService.openToast({
                        title: this.successTitle,
                        message: 'Draft-ul a fost încărcat cu succes.',
                        type: this.successIcon,
                    });

                    // this.router.navigate([
                    //     this.SOLICITARI_URBANISM_URL,
                    //     this.getNumeSolicitare(),
                    //     'add-cerere',
                    // ]);
                },
                error: (error: any) => {
                    console.error(`Error loading draft ${draftData.id}:`, error);
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        errorMessage,
                        this.errorIcon
                    );
                },
                complete: () => {
                    subscription.unsubscribe();
                },
            });
    }

    printDocument(endpoint: string) {
        this.notificationService
            .warningSwal(
                'Generare cerere completată',
                'Cererea dumneavoastră va fi generată fără fișierele încărcate (dacă există).',
                environment.config.customNotifications.icons.warning,
                'Confirmare',
                'Anulare',
                true
            )
            .then((result) => {
                if (result.isConfirmed) {
                    let formValueString = '';
                    const data = { json: this.formCerere?.value };
                    // Check if 'formUpload' exists before removing it
                    if ('formUpload' in data) {
                        // 'formUpload' exist
                        const formCerereWithoutUpload = { ...data };
                        delete formCerereWithoutUpload.formUpload;
                        formValueString = JSON.stringify(formCerereWithoutUpload);
                    } else {
                        // 'formUpload' doesn't exist
                        formValueString = JSON.stringify(data);
                        console.log(formValueString);
                    }

                    const headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                    });

                    this.http
                        .post(this.printFormUrl + endpoint, formValueString, {
                            headers,
                            responseType: 'arraybuffer',
                        })
                        .subscribe({
                            next: (res: any) => {
                                const blob = new Blob([res], { type: 'application/pdf' });

                                const url = window.URL.createObjectURL(blob);
                                window.open(url, '_blank');

                                let message = 'Solicitarea dvs. a fost generată cu succes';
                                this.notificationService.warningSwal(
                                    this.successTitle,
                                    message,
                                    this.successIcon
                                );
                            },
                            error: (err: any) => {
                                this.notificationService.handleHttpError(err);
                            },
                        });
                }
            });
    }

    getIsLoading(): boolean {
        return this.isLoading;
    }

    navigateToNewTab(route: string) {
        window.open(route, '_blank');
    }

    getFilteredDocumentsToUpload(documents: UploadDocument[]) {
        let beneficiarControl = this.getControlValue('formBeneficiar', 'beneficiar')

        const beneficiarExists = beneficiarControl.length > 0;

        let shouldShowCI = this.userService.isUserPersoanaFizica();
        let shouldShowCIF = this.userService.isUserPersoanaJuridica();

        if (beneficiarExists) {
            shouldShowCI = beneficiarControl.some((beneficiar: any) => beneficiar.beneficiar_type === '1')
            shouldShowCIF = beneficiarControl.some((beneficiar: any) => beneficiar.beneficiar_type === '2')
        }

        return documents.filter((document) => {
            if (document.key === 'document_ci') {
                return shouldShowCI;
            }
            if (document.key === 'document_cif') {
                return shouldShowCIF;
            }

            return true;
        });
    }
}
