import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CarieraService {
    private basePath = environment.interop.authCore.basePath;
    private getDocumenteList = this.basePath + environment.interop.authCore.institutii.cariera.getDocumente;
    public fileDownloadUrl = this.basePath + environment.interop.authCore.institutii.downloadFile;

    constructor(private http: HttpClient) { }

    getDocumente(instition: string) {
        return this.http.get(this.getDocumenteList + instition);
    }
}
