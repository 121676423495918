import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth-module/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ClientService {
    baseUrl = environment.interop.programariCore.basePath + 'client/';

    constructor(private http: HttpClient, private router: Router, private authService: AuthService) { }

    actDeIdentitate(actDeIdentitate: any, moduleName: string = 'act-de-identitate') {
        console.log(actDeIdentitate);

        // see what URL will be given by Sebi then change the http url based on isAuthenticated
        //let url = this.authService.isAuthenticated() ? environment.interop.programariCore.basePath + 'citizen/' : environment.interop.programariCore.basePath + 'client/';
        return this.http.post(`${this.baseUrl}${moduleName}`, actDeIdentitate).toPromise();
    }

    casatorie(casatorie: any) {
        console.log(casatorie);
        return this.http.post(`${this.baseUrl}casatorie`, casatorie).toPromise();
    }

    transcriere(transcriere: any) {
        console.log(transcriere);
        return this.http.post(`${this.baseUrl}transcriere`, transcriere).toPromise();
    }

    corespondenta(corespondenta: any) {
        console.log(corespondenta);
        return this.http.post(`${this.baseUrl}corespondenta`, corespondenta).toPromise();
    }

    suportOnline(suportOnline: any) {
        return this.http.post(`${this.baseUrl}suport-online`, suportOnline).toPromise();
    }

    getSarbatoriLegale() {
        return this.http.get(`${this.baseUrl}sarbatori-legale`).toPromise();
    }

    getFullDaysForCurrentYear(type: string, locationId: string, metadata: any) {
        const stringifiedMetadata = JSON.stringify(metadata);
        const encodedMetadata = encodeURIComponent(stringifiedMetadata);
        return this.http.get(`${this.baseUrl}get-full-days-for-current-year/${type}/${locationId}/${encodedMetadata}`).toPromise();
    }

    getLocations(type: string) {
        return this.http.get(`${this.baseUrl}locations/${type}`).toPromise();
    }

    upload(formData: FormData) {
        return this.http.post(this.baseUrl + 'upload', formData).toPromise();
    }

    getGeneratedDocumentsList(documentSearchData: any) {
        return this.http.post(this.baseUrl + 'getGeneratedDocumentsList', documentSearchData).toPromise()
    }

    downloadGeneratedDocument(documentDownloadData: any) {
        return this.http.post(this.baseUrl + 'downloadGeneratedDocument', documentDownloadData, { responseType: 'arraybuffer' });
    }

    getAllowedIpList() {
        return this.http.get(this.baseUrl + 'allowed-ip-list').toPromise();
    }

    isAllowedIp() {
        return this.http.get(this.baseUrl + 'is-allowed-ip').toPromise();
    }

    validateSolicitariDuplicateCnp(data: any) {
        return this.http.post(this.baseUrl + 'validateSolicitariDuplicateCnp', data).toPromise()
    }

    makeAppointment(data: any) {
        return this.http.post(this.baseUrl + 'make-appointment', data).toPromise();
    }

    resetAppointment(data: any) {
        return this.http.post(this.baseUrl + 'reset-appointment', data).toPromise();
    }

    requestPaymentLink(data: any) {
        return this.http.post(this.baseUrl + 'request-payment', data).toPromise();
    }


    searchAppointment(data: any) {
        return this.http.post(this.baseUrl + 'get-programare', data).toPromise();
    }

    cancelAppointment(appointmentId: any) {
        return this.http.put(this.baseUrl + 'anulare-programare/' + appointmentId, null).toPromise();
    }

    navigateToProgramariOnline(programareUrl: string = '') {
        let baseProgramariOnline = this.authService.isAuthenticated() ? 'dashboard/solicitari/programari-online' : 'public/programari-online';
        console.log(`${baseProgramariOnline}/${programareUrl}`);
        const url = `${baseProgramariOnline}/${programareUrl}`
        this.router.navigate([url]);
    }
}