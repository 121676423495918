<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="achizitii-container">
    <div class="achizitii-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="achizitii-welcome-card-content d-flex flex-column gap-4 justify-content-start align-self-stretch">
            <span class="institution">
                {{institution === 'dgaspc' ? 'Direcția Generală de Asistență Socială și Protecția Copilului' : ''}}
            </span>
            <span class="subtitle">Achiziții Publice<span *ngIf="selectedYear"> {{selectedYear}}</span></span>
        </div>
    </div>

    <div class="achizitii-content d-flex flex-column align-items-start align-self-stretch">
        <div class="achizitii-content-container d-flex flex-column align-self-stretch">
            <p *ngIf="!selectedYear" class="header-text text-center">Selectați anul pentru a vizualiza documentele</p>
            <div class="row">
                <div class="col-sm-12" *ngIf="!selectedYear; else dataView">
                    <ul class="year-list">
                        <li *ngFor="let year of years">
                            <p class="pointer" (click)="selectYear(year)">
                                <span>{{year}}</span>
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </p>
                        </li>
                    </ul>
                </div>
                <ng-template #dataView>
                    <div class="p-2">
                        <button (click)="goBack()" class="navigation__btn" mat-stroked-button color="primary">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                            ÎNAPOI
                        </button>
                    </div>
                    <div class="achizitii-list col-sm-12" *ngFor="let item of documente">
                        <ng-container *ngIf="getAllDocuments(item.documents).length">
                            <h2 class="mt-3">{{ item.name }}</h2>

                            <div *ngFor="let doc of getAllDocuments(item.documents)">
                                <p (click)="downloadFile(doc.id)">
                                    <i class="fa-solid fa-cloud-arrow-down"></i>
                                    {{ doc.web_name }}
                                </p>
                                <mat-divider class="my-3"></mat-divider>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>

<div class="section-spinner position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
    *ngIf="isLoading">
    <div class="spinner">
        <mat-spinner></mat-spinner>
        <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
    </div>
</div>