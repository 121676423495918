import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-module/services/auth.service';
import { ConfigExtensions } from '../utils/config-extensions';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../utils/services/dialog.service';
import { SuportOnlineComponent } from '../components/suport-online/suport-online.component';
import { NotificationService } from '../services/notification.service';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.success;
    successIcon: string = environment.config.customNotifications.icons.success;

    appName: string = environment.config.appName;
    public production: boolean = environment.production;
    public portalLive: boolean = environment.portalLive;
    activeRoute!: string;
    username: string = '';
    usernameLetters: string = '';
    sidenavOpen = false;

    @ViewChildren('sidenavContainer, menuButton, closeButton') sidenavElements!: QueryList<ElementRef>;

    constructor(
        private router: Router,
        public authService: AuthService,
        private eRef: ElementRef,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private notificationService: NotificationService,
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {

                this.activeRoute = this.router.url;
            });
    }

    ngOnInit(): void {
        this.getUserDetails();
    }

    @HostListener('document:click', ['$event'])
    clickout(event: Event): void {
        // Check if the clicked element is one of the specified elements
        const clickedElement = event.target as HTMLElement;
        if (!this.sidenavElements.some(elementRef => elementRef.nativeElement.contains(clickedElement))) {
            // If none of the elements were clicked, toggle the sidenav
            this.sidenavOpen = false;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        const windowWidth = window.innerWidth;

        if (windowWidth > 1200) {
            this.sidenavOpen = false;
        }
    }

    getUserDetails() {
        const user = localStorage.getItem(environment.config.userKey);
        if (!user) return;

        let jsonUser = JSON.parse(user);
        if (jsonUser && jsonUser.prenume && jsonUser.nume) {
            this.username = jsonUser.prenume + ' ' + jsonUser.nume;
        } else {
            this.username = 'Not logged in';
        }

        this.extractLetters(this.username);
    }

    extractLetters(username: string) {
        const firstLetters = username.split(' ').map((word) => word.charAt(0));
        this.usernameLetters = firstLetters.slice(0, 2).join('');
    }

    toggleSidenav() {
        this.sidenavOpen = !this.sidenavOpen;
    }

    isProduction() {
        return ConfigExtensions.isProductionWithLimitedFeatures();
    }

    getResponsivePanelClass() {
        // Use window.innerWidth to determine the screen width
        const width = window.innerWidth;
        if (width <= 390) {
            // Return a class designed for very small screens
            return 'scroll-modalbox-very-small'; // Class for screens 390px or less
        } else if (width < 768) {
            // Return a class for medium/small screens, such as tablets or large phones
            return 'scroll-modalbox-small'; // Adjusted class name for clarity
        } else {
            // Return the default class for larger screens
            return 'scroll-modalbox';
        }
    }

    openSuport() {
        // Dynamically set panelClass based on screen size
        const panelClass = this.getResponsivePanelClass();

        let configData = Object.assign({}, this.dialogService.getDialogConfig(), {
            data: {
                PageSection: null,  // Add page section for faqs if it exists
            },
            panelClass: panelClass, // Use dynamic panelClass based on screen size
        });

        const dialogRef = this.dialog.open(SuportOnlineComponent, configData);

        dialogRef.afterClosed().subscribe((res: any) => {
            const response =
                typeof res.data !== 'undefined' &&
                    res.data !== null &&
                    typeof res.data.id !== 'undefined'
                    ? res.data
                    : null;

            if (response !== null) {
                let message =
                    'Solicitarea dvs cu numarul ' +
                    response.id +
                    '/' +
                    response.date +
                    ' a fost trimisa cu succes. ';
                this.notificationService.warningSwal(
                    this.successTitle,
                    message,
                    this.successIcon
                );
            }
        });
    }

    openSuportSidenav() {
        this.toggleSidenav();
        this.openSuport();
    }
}
