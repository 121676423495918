import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private baseUrl = environment.interop.authCore.basePath;
    private getTariActiveUrl = this.baseUrl + environment.interop.authCore.tari.getTariActive;
    private getJudeteActiveUrl = this.baseUrl + environment.interop.authCore.judete.getJudeteActive;
    private getLocalitatiActiveUrl = this.baseUrl + environment.interop.authCore.localitati.getLocalitatiActive;

    constructor(private http: HttpClient) { }

    getTariActive() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getTariActiveUrl
            )
            .pipe(
                map((response) =>
                    response.data
                        .map((item) => ({
                            id: item.id,
                            name: item.name,
                        }))
                ),
                catchError((error) => {
                    console.error('Error in get tari:', error);
                    return throwError(error);
                })
            );
    }

    getJudeteActive() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getJudeteActiveUrl
            )
            .pipe(
                map((response) =>
                    response.data
                        .filter(item => item.name !== 'Altele')
                        .map((item) => ({
                            id: item.id,
                            name: item.name,
                        }))
                ),
                catchError((error) => {
                    console.error('Error in get judete:', error);
                    return throwError(error);
                })
            );
    }

    getLocalitatiActive(id_judet?: number, name?: string, id_country: number = Number(sessionStorage.getItem(environment.config.storageKey + 'tari'))) {
        let params = new HttpParams().set('id_country', id_country);

        if (id_judet !== undefined) {
            params = params.set('id_judet', id_judet);
        }
        if (name !== undefined) {
            params = params.set('name', name);
        }

        return this.http.get(this.getLocalitatiActiveUrl, { params });
    }
}