import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';
import { AchizitiiService } from './achizitii.service';
import { AppFilesService } from 'src/app/services/app-files.service';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-achizitii',
    templateUrl: './achizitii.component.html',
    styleUrls: ['./achizitii.component.scss']
})
export class AchizitiiComponent {
    public portalLive = environment.portalLive;
    institution!: string;
    selectedYear: number | null = null;
    years: number[] = [];
    documente!: any[];

    isLoading = false;
    helper = helper;

    constructor(
        private route: ActivatedRoute,
        private achizitiiService: AchizitiiService,
        private appFilesService: AppFilesService
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.institution = params.get('institution')!;
            this.getYearsForInstitution(this.institution);
        });
    }

    getYearsForInstitution(institution: string) {
        this.isLoading = true;
        this.achizitiiService.getYears(institution)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (result: any) => {
                    this.years = result.data;
                },
                error: (error) => {
                    console.error('Error fetching years', error);
                }
            });
    }

    selectYear(year: number) {
        this.selectedYear = year;
        this.isLoading = true;

        this.achizitiiService.getDocumente(this.institution, year)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (result: any) => {
                    this.documente = result.data;
                },
                error: (error) => {
                    console.error('Error fetching documents', error);
                }
            });
    }

    getAllDocuments(documents: any): any[] {
        if (!documents) return [];
        return Object.values(documents).flat();
    }

    // Reset to the years view
    goBack() {
        this.selectedYear = null;
        this.documente = [];
        this.getYearsForInstitution(this.institution);
    }

    downloadFile(id: number) {
        let fullPath = this.achizitiiService.fileDownloadUrl + id;
        this.isLoading = true;
        this.appFilesService.downloadFile(fullPath)
            .then(() => {
                this.isLoading = false;
            })
            .catch(err => {
                console.error('File download error: ', err);
                this.isLoading = false;
            })
    }
}
