import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarieraComponent } from './cariera.component';
import { CarieraRoutingModule } from './cariera-routing.module';
import { AccessibilityToolbarModule } from 'src/app/components/accessibility-toolbar/accessibility-toolbar.module';
import { MaterialModule } from 'src/app/material.module';
import { SharedFooterModule } from '../shared-footer/shared-footer.module';

@NgModule({
    declarations: [CarieraComponent],
    imports: [
        CommonModule,
        CarieraRoutingModule,
        AccessibilityToolbarModule,
        MaterialModule,
        SharedFooterModule
    ],
    exports: [CarieraComponent]
})
export class CarieraModule { }
