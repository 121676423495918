<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="bulevart-container">
    <div class="bulevart-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="bulevart-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <span>BulevART</span>
        </div>
    </div>

    <div class="bulevart-content d-flex flex-column align-items-start align-self-stretch">
        <div class="bulevart-content-container d-flex flex-column align-self-stretch text-center">
            <h1>Pagina este în lucru.</h1>
            <h1>Vă mulțumim pentru înțelegere și reveniți curând pentru noutăți.</h1>
        </div>
    </div>

    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>