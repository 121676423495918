import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AchizitiiComponent } from './achizitii.component';
import { AchizitiiRoutingModule } from './achizitii-routing.module';
import { AccessibilityToolbarModule } from 'src/app/components/accessibility-toolbar/accessibility-toolbar.module';
import { MaterialModule } from 'src/app/material.module';
import { SharedFooterModule } from '../shared-footer/shared-footer.module';


@NgModule({
    declarations: [AchizitiiComponent],
    imports: [
        CommonModule,
        AchizitiiRoutingModule,
        AccessibilityToolbarModule,
        MaterialModule,
        SharedFooterModule
    ],
    exports: [AchizitiiComponent]
})
export class AchizitiiModule { }
