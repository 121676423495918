import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SolicitariComponent } from './solicitari.component';
import { InstitutieSelectorComponent } from './institutie-selector/institutie-selector.component';
import { MaterialModule } from '../../material.module';
import { AnnouncementsModule } from '../../shared/announcements/announcements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewSolicitareComponent } from './view-solicitare/view-solicitare.component';
import { TermeniConditiiModalModule } from 'src/app/shared/termeni-conditii-modal/termeni-conditii-modal.module';
import { CanActivateInDevGuard } from 'src/app/guards/can-activate-in-dev.guard';

const routes: Routes = [
    {
        path: '',
        component: SolicitariComponent,
    },
    {
        path: 'institutie',
        component: InstitutieSelectorComponent,
        canActivate: [CanActivateInDevGuard]
    },
    {
        path: 'institutie/urbanism',
        loadChildren: () =>
            import('./urbanism/solicitari-urbanism.module').then(
                (m) => m.SolicitariUrbanismModule
            ),
    },
    {
        path: 'institutie/dgitl',
        loadChildren: () =>
            import('./dgitl/solicitari-dgitl.module').then(
                (m) => m.SolicitariDgitlModule
            ),
        canActivate: [CanActivateInDevGuard]
    },
    {
        path: 'institutie/autorizari-comerciale',
        loadChildren: () =>
            import('./autorizari-comerciale/solicitari-autorizari-comerciale.module').then(
                (m) => m.SolicitariAutorizariComercialeModule
            ),
        canActivate: [CanActivateInDevGuard]
    },
    {
        path: 'institutie/asistenta-sociala',
        loadChildren: () =>
            import('./asistenta-sociala/solicitari-asistenta-sociala.module').then(
                (m) => m.SolicitariAsistentaSocialaModule
            ),
        canActivate: [CanActivateInDevGuard]
    },
    {
        path: 'programari-online',
        loadChildren: () =>
            import('../programari-online/programari-online.module').then(
                (m) => m.ProgramariOnlineModule
            ),
        canActivate: [CanActivateInDevGuard]
    },
    {
        path: 'view-solicitare/:solicitareType/:solicitareId',
        component: ViewSolicitareComponent,
    }
];
@NgModule({
    declarations: [InstitutieSelectorComponent, SolicitariComponent, ViewSolicitareComponent],
    exports: [RouterModule],
    imports: [
        CommonModule,
        MatPaginatorModule,
        RouterModule.forChild(routes),
        MaterialModule,
        AnnouncementsModule,
        FormsModule,
        ReactiveFormsModule,
        TermeniConditiiModalModule
    ],
})
export class SolicitariModule { }
