import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarieraComponent } from './cariera.component';

const routes: Routes = [
    {
        path: ':institution',
        component: CarieraComponent
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class CarieraRoutingModule { }
