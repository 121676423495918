import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LoggedLinksEnum } from 'src/app/models';
import { ConfigExtensions } from 'src/app/utils/config-extensions';

@Component({
    selector: 'app-institutie-selector',
    templateUrl: './institutie-selector.component.html',
    styleUrls: ['./institutie-selector.component.scss']
})
export class InstitutieSelectorComponent {
    platformName = environment.config.platformName;

    env = environment;
    loggedUserLinks = LoggedLinksEnum;

    constructor(private router: Router) { }

    navigateToNew(route: string) {
        this.router.navigate([route]);
    }

    navigateToNewTab(route: string) {
        window.open(route, '_blank');
    }

    isProduction() {
        return environment.production;
    }
}
