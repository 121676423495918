import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeclaratiiComponent } from './declaratii.component';
import { DeclaratiiRoutingModule } from './declaratii-routing.module';
import { AccessibilityToolbarModule } from 'src/app/components/accessibility-toolbar/accessibility-toolbar.module';
import { MaterialModule } from 'src/app/material.module';
import { SharedFooterModule } from '../shared-footer/shared-footer.module';


@NgModule({
    declarations: [DeclaratiiComponent],
    imports: [
        CommonModule,
        DeclaratiiRoutingModule,
        AccessibilityToolbarModule,
        MaterialModule,
        SharedFooterModule
    ],
    exports: [DeclaratiiComponent]
})
export class DeclaratiiModule { }
