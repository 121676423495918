import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeclaratiiService {
    private basePath = environment.interop.authCore.basePath;
    private getYearsList = this.basePath + environment.interop.authCore.institutii.declaratii.getYears;
    private getDeclaratiiList = this.basePath + environment.interop.authCore.institutii.declaratii.getDeclaratii;
    public fileDownloadUrl = this.basePath + environment.interop.authCore.institutii.downloadFile;

    constructor(private http: HttpClient) { }

    getYears(instition: string) {
        return this.http.get(this.getYearsList + instition);
    }

    getDeclaratii(instition: string, year: number) {
        return this.http.get(this.getDeclaratiiList + instition + '/' + year);
    }
}
