import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-bulevart',
    templateUrl: './bulevart.component.html',
    styleUrls: ['./bulevart.component.scss']
})
export class BulevartComponent {
    portalLive: boolean = environment.portalLive;
}
