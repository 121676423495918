import { Component, Input, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { AuthService } from '../../auth-module/services/auth.service'
import { finalize } from 'rxjs';

import { AlertDialogComponent } from 'src/app/utils/alert-dialog/alert-dialog.component';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'app-suport-online',
    templateUrl: './suport-online.component.html',
    styleUrls: ['./suport-online.component.scss']
})
export class SuportOnlineComponent {
    PageSection: any = null
    FAQList: any = []
    hasFaqs: boolean = false;

    public isCollapsed = false;

    frequentDialogRef: any
    isLoading = false;

    // dialogRef: any
    showSolicitare: boolean = false;

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<SuportOnlineComponent>,
        private dialogService: DialogService,
        private notificationService: NotificationService
    ) {
        this.PageSection = data.PageSection
    }

    closeDialog(data: any = null) {
        this.dialogRef.close({
            data: data
        });
    }

    cancel() {
        this.dialogRef.close({
            data: 'cancel'
        });
    }

    ngOnInit(): void {
        if (this.PageSection) {
            this.hasFaqs = true;
            this.getFAQS()
        } else {
            this.hasFaqs = false;
            this.loadSolicitare();
        }
    }

    getFAQS() {
        var self = this
        this.isLoading = true
        this.authService.getFAQ(this.PageSection)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: async (res: any) => {
                    console.log(res, 'faq component ok')
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error)
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            self.FAQList = response.data
                            self.isLoading = false
                            return;
                        }
                    } else {
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        await self.notificationService.warningSwal(environment.config.customNotifications.headers.error, errorMessage, environment.config.customNotifications.icons.error);
                    }
                },
                error: (res: any) => {
                    console.log(res, 'faq component nok')
                    this.notificationService.handleHttpError(res.error);
                }
            });
    }

    loadSolicitare() {
        this.showSolicitare = true;
    }

    handleShowSolicitareChange(eventData: boolean) {
        this.showSolicitare = eventData;
    }
}